
<table class="table table-striped mt-3">
    <thead>
      <tr>
       <th scope="col">#</th>
       <th scope="col">Proje Adı</th>
       <th scope="col">Açıklama</th>
       <th scope="col">Görsel</th>
       <th scope="col">Sıra</th>
       <th scope="col">Vitrin</th>
       <th scope="col">İşlem</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let p of projectService.projects">
        <th scope="row">{{p.projectId}}</th>
        <td>{{p.projectName}}</td>
        <td style="max-width:200px; word-wrap: break-word;"><div [innerHTML]="p.projectDescription | htmldecoder"></div></td>
        <td><a href="{{path}}{{p.projectPicture}}" target="_blank"><img src="{{path}}{{p.projectThumbnail}}" class="img-thumbnail" style="max-width: 150px; max-height: 150px;"/></a></td>
        <td>{{p.order}}</td>
        <td>{{p.isVitrin}}</td>
        <td>
          <button type="button" (click)="deleteProject(p.projectId)" class="btn btn-danger btn-sm">Sil</button>
          <button type="button" (click)="shareVirtualTours(p.projectId)" class="btn btn-warning btn-sm" >Sanal Tur Paylaş</button>
        </td>
     
      </tr>
      
    </tbody>
  </table>

