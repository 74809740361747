import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GalleryDto } from 'src/app/models/galleryDto';
import { GalleryService } from 'src/app/services/gallery.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-list-and-delete-summary-galleries',
  templateUrl: './admin-list-and-delete-summary-galleries.component.html',
  styleUrls: ['./admin-list-and-delete-summary-galleries.component.css']
})
export class AdminListAndDeleteSummaryGalleriesComponent implements OnInit {
  galleries: GalleryDto[];

  path = environment.getUrl;
  constructor(private galleryService:GalleryService,private toastr:ToastrService) { }

  ngOnInit(): void {
    this.getGalleriesDtoAll();
    
  }
 
  getGalleriesDtoAll(){
    this.galleryService.getGalleriesDtoAll().subscribe(response=>{
    this.galleries=response;
    });
  }

  deleteGallery(id:number){
    this.galleryService.deleteGallery(id).subscribe(response=>{},errorResponse=>{this.toastr.success(errorResponse.error.text,"Başarılı"),this.getGalleriesDtoAll()});
  }

}
