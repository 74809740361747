<app-navi></app-navi>
<div class="container margin_top_70" style="min-height: 100%">
  <div class="row">
    <h1>{{ project?.projectName }}</h1>
    <h6>ID:{{ project?.projectId }}</h6>
  </div>
  <div class="row">
    <div class="col-md-4 col-sm-12">
      <a [href]="path + project?.projectPicture" target="_blank"
        ><img class="img-fluid" [src]="path + project?.projectThumbnail"
      /></a>
    </div>
    <div class="col col-md-8 col-sm-12">
      <div
        class="card-body"
        style="
          font-size: 1.2rem;
          letter-spacing: 2px;
          font-weight: 600;
          opacity: 0.7;
          word-wrap: break-word;
        "
      >
      <div [innerHTML]="project?.projectDescription | htmldecoder"></div>

      </div>
    </div>
  </div>
  <div class="mb-2" *ngIf="project"></div>
  <div class="card" *ngIf="virtualToursDto" style="border: none">
    <div class="card-header d-flex justify-content-end">
      Sanal Turlar - Görüntülemek için görselin üzerine basınız.
    </div>
    <div class="card-body" style="border: none">
      <blockquote class="blockquote mb-0" style="border: none">
        <div class="row" style="border: none">
          <div
            class="col col-12 col-sm-6 col-md-2 col-lg-2 col-xl-2"
            *ngFor="let vt of virtualToursDto"
            style="border: none"
          >
            <div class="thumbnail" style="border: none">
              <a
                routerLink="/sanalturlar/sanaltur/{{ vt.dataUrl }}"
                routerLinkActive="active"
                target="_blank"
                style="border: none"
              >
                <img
                  src="{{ path }}{{ vt.pictureThumbnail }}"
                  class="img-thumbnail"
                  alt="{{ vt.description }}"
                />
              </a>
              <div class="text-center">
                <a routerLink="/sanalturlar/sanaltur/{{ vt.dataUrl }}"
                routerLinkActive="active"
                target="_blank"
                ><h4>{{ vt.name }}</h4></a>
                <div [innerHTML]="vt.description | htmldecoder"></div>
              </div>
            </div>
          </div>
        </div>
      </blockquote>
    </div>
  </div>
  <!-- <div [innerHTML]="htmldata | htmldecoder"></div> -->
  <app-readonlycke
  style="border:0ch"
    [(ngModel)]="htmldata"
    id="ckeditor-content"
    [readonly]="readonly"
    [config]="config"
  ></app-readonlycke>
  <!-- <div class="card">
     <div class="card-header">
      <div class="row">
        <div class="col col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
          <label>Kategori</label>
          <mat-form-field appearance="fill">
            <mat-label>Seçiniz</mat-label>
            <mat-select
              [(ngModel)]="categoryId"
              (ngModelChange)="getGalleriesbyFilter()"
            >
            <mat-option

                [value]="0"
                >Seçiniz</mat-option
              >
              <mat-option
                *ngFor="let category of categoryService.categoriesFilter"
                [value]="category.categoryId"
                >{{ category.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
          <label>Mimari</label>
          <mat-form-field appearance="fill">
            <mat-label>Seçiniz</mat-label>
            <mat-select [(ngModel)]="categoryBase"  (ngModelChange)="getGalleriesbyFilter()">
              <mat-option

                [value]="0"

                >Seçiniz</mat-option
              >
              <mat-option
                *ngFor="let category of categoryService.categoriesBaseFilter"
                [value]="category.categoryId"

                >{{ category.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <p>
            Filitrelemek için seçim yapınız, tasarımlara bakmak için görselin
            üzerine tıklayınız.
          </p>
        </div>
      </div>
    </div> -->

  <!-- <div class="card-body">
      <blockquote class="blockquote mb-0">
        <div class="row">
          <div
            class="col col-6 col-sm-12 col-md-2 col-lg-2 col-xl-4 mb-1"
            *ngFor="let image of album; let i = index"
          >
            <img
              [src]="image.thumb"
              (click)="open(i)"
              class="img-thumbnail border-0"
            />
          </div>
        </div>
      </blockquote>
    </div>
  </div> -->
</div>
<app-footer></app-footer>

<style>
  #ckeditor-content p {
    border: none;
  }
  p,
  table,
  th,
  td {
    border: none;
  }
  .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
    border: none;
  }
</style>
