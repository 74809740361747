import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Gallery } from '../models/gallery';
import { from, Observable } from 'rxjs';

import { GalleryDto } from '../models/galleryDto';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class GalleryService {
  public GalleriesFilter: Gallery[];
  public galleries: Gallery[];
  public album: any = [];
  constructor(private httpClient: HttpClient) {
    //this.getGalleryList();
  }

  addGallery(gallery: Gallery, files: FileList): Observable<any> {
    let newPath = environment.getApiUrl + 'Galleries/add';
    const formData: FormData = new FormData();
    formData.append('projectId', gallery.projectId.toString());
    for (let i = 0; i < files.length; i++) {
      formData.append('Images', files[i]);
    }
    formData.append('isOnlyAdmin',gallery.isOnlyAdmin)
    formData.append('picture', gallery.picture);
    formData.append('thumbnail', gallery.thumbnail);
    formData.append('name', gallery.name);
    formData.append('categoryId', gallery.categoryId.toString());
    formData.append('categoryBase', gallery.categoryBase.toString());

    return this.httpClient.post(
      environment.getApiUrl + '/galleries/',
      formData,
      { responseType: 'text' }
    );
  }

  getGalleryList() {
    this.httpClient
      .get<Gallery[]>(environment.getApiUrl + '/galleries/getall')
      .subscribe((response) => {
        this.galleries = response;
      });
  }

  getLightbox(array:Gallery[]) {
    const myalbum = this.album;
    this.album.length = 0;
    array.forEach(function (gallery) {
      let src = environment.getUrl + gallery.picture;
      let caption = gallery.name;
      let thumb = environment.getUrl + gallery.thumbnail;
      myalbum.push({ src: src, caption: caption, thumb: thumb });
    });
  }

  getGalleryById(id: number): Observable<Gallery> {
    return this.httpClient.get<Gallery>(
      environment.getApiUrl + '/galleries/getbyid?galleryId=' + id
    );
  }

  getGalleriesByClaim(projectId: number, userId: number) {
    this.httpClient
      .get<Gallery[]>(
        environment.getApiUrl +
          '/galleries/getgalleriesbyclaim?projectId=' +
          projectId +
          '&userId=' +
          userId
      )
      .subscribe((response) => {
        this.galleries = response;
        this.getLightbox( this.galleries);
      });
  }
  getGalleriesbyFilter(categoryId:number,categoryBaseId:number)
  {
    if(categoryId==0 && categoryBaseId==0)
    {
      this.getLightbox(this.galleries);
    }
    else if(categoryId==0){
      this.GalleriesFilter=this.galleries.filter(x=>x.categoryBase==categoryBaseId);
      this.getLightbox(this.GalleriesFilter);
    }
    else if(categoryBaseId==0){
      this.GalleriesFilter=this.galleries.filter(x=>x.categoryId==categoryId);
      this.getLightbox(this.GalleriesFilter);
    }
    else{
      this.GalleriesFilter=this.galleries.filter(x=>x.categoryId==categoryId && x.categoryBase==categoryBaseId);
      this.getLightbox(this.GalleriesFilter);
    }
    
  }
  getGalleriesDtoAll(): Observable<GalleryDto[]> {
    return this.httpClient.get<GalleryDto[]>(
      environment.getApiUrl + '/galleries/getalldto'
    );
  }

  updateGallery(gallery: Gallery, file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('galleryId', gallery.galleryId.toString());
    formData.append('projectId', gallery.projectId.toString());
    formData.append('picture', gallery.picture);
    formData.append('name', gallery.name);
    formData.append('categoryId', gallery.categoryId.toString());
    formData.append('CategoryBase', gallery.categoryBase.toString());
    formData.append('Image', file);
    return this.httpClient.put(
      environment.getApiUrl + '/galleries/',
      formData,
      { responseType: 'text' }
    );
  }

  deleteGallery(id: number) {
    return this.httpClient.request(
      'delete',
      environment.getApiUrl + '/galleries/',
      { body: { galleryId: id } }
    );
  }
}
