<!-- Footer -->
<footer class="bg-dark text-center text-white">
  <!-- Grid container -->
  <div class="container p-4">


    <!-- Section: Links -->
    <section class="footer_details">
      <!--Grid row-->
      <div class="row">
        <!--Grid column-->
        <div class="col-lg-4 col-md-6">
          <h5 class="text-uppercase text-warning text-left">İletişim</h5>

          <ul class="list-unstyled mb-0 text-left">
            <li class="">
              <p><i class="fas fa-phone"></i> +90 (533) 931 20 88</p>
              <p>
                <i class="fas fa-envelope-open"></i>info@kadirguzinmimarlik.com
              </p>
              <p>
                <i class="fas fa-file-alt"></i> V.D/No: Pamukkale
                V.D./5481599677
              </p>
              <p>
                <i class="fas fa-map-marked-alt"></i> Kınıklı Mah. 2591 Sok.
                No:29 Kat:1 Daire:4 Pamukkale/DENİZLİ
              </p>
            </li>
          </ul>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-lg-4 col-md-6 mb-4 mb-md-0">
          <img src="../../../assets/images/kg_logo.jpeg" style="width: 250px" />
           <!-- Section: Text -->
    <section class="mb-4 footer_info">
      <h3>Tasarımın Sanatsal Hali</h3>
    </section>
    <!-- Section: Text -->
          <!-- Section: Social media -->
    <section class="mb-4">
      <!-- Instagram -->
      <a
        class="btn btn-outline-light btn-floating m-1"
        href="https://www.instagram.com/kadirguzinmimarlik/"
        role="button"
        ><i class="fab fa-instagram"></i
      ></a>

      <!-- Facebook -->
      <a
        class="btn btn-outline-light btn-floating m-1"
        href="https://www.facebook.com/kgdesignmimarlik/"
        role="button"
        ><i class="fab fa-facebook-f"></i
      ></a>

      <!-- Twitter
        <a class="btn btn-outline-light btn-floating m-1" href="#!" role="button"
          ><i class="fab fa-twitter"></i
        ></a>-->

      <!-- Google
        <a class="btn btn-outline-light btn-floating m-1" href="#!" role="button"
          ><i class="fab fa-google"></i
        ></a>-->

      <!-- Linkedin
        <a class="btn btn-outline-light btn-floating m-1" href="#!" role="button"
          ><i class="fab fa-linkedin-in"></i
        ></a>-->

      <!-- Github
        <a class="btn btn-outline-light btn-floating m-1" href="#!" role="button"
          ><i class="fab fa-github"></i
        ></a>-->
    </section>
    <!-- Section: Social media -->


          <!-- <h5 class="text-uppercase text-warning">Mimarlar</h5>

            <ul class="list-unstyled mb-0 text-left">
              <li>
                <p>Kadir Onur GÜZİN</p>
                <p>Umran GÜZİN</p>
              </li>

            </ul> -->
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-lg-4 col-md-6 mb-4 mb-md-0">
          <h5 class="text-uppercase text-warning text-right">Hızlı Menü</h5>

          <ul class="list-unstyled mb-0 text-right">
            <li class="nav-item">
              <a class="nav-link text-white" routerLink="/projeler">Projeler</a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-white" routerLink="/hakkimizda">Hakkımızda</a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-white" routerLink="/iletisim">İletişim</a>
            </li>
          </ul>
        </div>
        <!--Grid column-->
      </div>
      <!--Grid row-->
    </section>
    <!-- Section: Links -->
  </div>
  <!-- Grid container -->

  <!-- Copyright -->
  <div class="text-center p-3" style="background-color: rgba(0, 0, 0, 0.2)">
    © 2020 Copyright:
    <a class="text-white" href="http://www.kadirguzinmimarlik.com/"
      >kadirguzinmimarlik.com</a
    >
    <a class="text-white" href="https://www.linkedin.com/in/erkinakdeniz/"
      >| Erkin</a
    >
  </div>
  <!-- Copyright -->
</footer>
<!-- Footer -->
