import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { AboutComponent } from './components/about/about.component';
import { AdminAddComponent } from './components/admin-add/admin-add.component';
import { AdminListAndDeleteComponent } from './components/admin-list-and-delete/admin-list-and-delete.component';
import { AdminComponent } from './components/admin/admin.component';
import { ContactComponent } from './components/contact/contact.component';
import { CustomerRouterComponent } from './components/customer-router/customer-router.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { IframeLayoutComponent } from './components/iframe/iframe-layout/iframe-layout.component';
import { IframeComponent } from './components/iframe/iframe.component';
import { LoginComponent } from './components/login/login.component';
import { NotfoundpageComponent } from './components/notfoundpage/notfoundpage.component';
import { ProjectdetailComponent } from './components/projectdetail/projectdetail.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { SliderComponent } from './components/slider/slider.component';
import { TestpageComponent } from './components/testpage/testpage.component';
import { LoginGuard } from './guards/login.guard';

const routes: Routes = [
  {path:"",pathMatch:"full",component:SliderComponent},
  {path:"sanalturlar",component:IframeLayoutComponent,children: [{ path: 'sanaltur/:vtname',component: IframeComponent }]},
  {path:"projeler",component:ProjectsComponent},
  {path:"hakkimizda",component:AboutComponent},
  {path:"iletisim",component:ContactComponent},
  {path:"test",component:TestpageComponent},
  {path:"sanalturlar/musteri/:customUrl/:projectId",component:CustomerRouterComponent},
  {path:"projeler/proje/:projectId",component:ProjectdetailComponent},
  {path:"forgotpassword",component:ForgotPasswordComponent},
  {path:"forgotpassword/:email/:verifiCode",component:ForgotPasswordComponent},
  {path:"admin",component:AdminComponent, canActivate:[LoginGuard]},
  {path:"404",component:NotfoundpageComponent},
  {path:"login",component:LoginComponent},
  {
    path: '**',
    redirectTo:'/404'
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
