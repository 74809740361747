import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})
export class AdminService {


  constructor(private httpClient: HttpClient) { 
  
  }
isLinkActive(link:string):Observable<string>{
  const formData: FormData = new FormData();
    formData.append('Link', link);
  return this.httpClient.post(
    environment.getApiUrl + '/ShareLinks/islinkactive',
    formData,
    { responseType: 'text' }
  );
}









}
