import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import{HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NaviComponent } from './components/navi/navi.component';
import { SliderComponent } from './components/slider/slider.component';
import { FooterComponent } from './components/footer/footer.component';
import { ProjectsComponent } from './components/projects/projects.component';
//lightbox
import { LightboxModule } from 'ngx-lightbox';
import { ProjectdetailComponent } from './components/projectdetail/projectdetail.component';
import { ProjectFilterPipePipe } from './pipes/project-filter-pipe.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import { NgVarDirective } from './directives/ng-var.directive';
import { GaleryFilterPipe } from './pipes/galery-filter.pipe';
import { AdminComponent } from './components/admin/admin.component'
import {MatTabsModule} from '@angular/material/tabs';
import { AdminAddComponent } from './components/admin-add/admin-add.component';
import { AdminListAndDeleteComponent } from './components/admin-list-and-delete/admin-list-and-delete.component';
import { AdminListAndDeleteSummaryProjectsComponent } from './components/admin-list-and-delete-summary-projects/admin-list-and-delete-summary-projects.component';
import { AdminListAndDeleteSummaryGalleriesComponent } from './components/admin-list-and-delete-summary-galleries/admin-list-and-delete-summary-galleries.component';
import {MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';

import { JwtModule } from '@auth0/angular-jwt';
import { LoginComponent } from './components/login/login.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { AdminListAndDeleteSummaryCategoriesComponent } from './components/admin-list-and-delete-summary-categories/admin-list-and-delete-summary-categories.component';
import { AdminListAndDeleteSummaryVirtualToursComponent } from './components/admin-list-and-delete-summary-virtual-tours/admin-list-and-delete-summary-virtual-tours.component';
import { AdminUpdateComponent } from './components/admin-update/admin-update.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { NotfoundpageComponent } from './components/notfoundpage/notfoundpage.component';
import { GeneralSettingsComponent } from './components/general-settings/general-settings.component';
import { IframeComponent } from './components/iframe/iframe.component';
import { CustomerRouterComponent } from './components/customer-router/customer-router.component';
import { ReadonlyckeComponent } from './readonlycke/readonlycke.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { AdminListAndDeleteSummaryPojectdetailsComponent } from './components/admin-list-and-delete-summary-pojectdetails/admin-list-and-delete-summary-pojectdetails.component';
import { AboutComponent } from './components/about/about.component';
import { ContactComponent } from './components/contact/contact.component';
import { TestpageComponent } from './components/testpage/testpage.component';
import { IframeLayoutComponent } from './components/iframe/iframe-layout/iframe-layout.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { EditorModule } from '@tinymce/tinymce-angular';
import { HtmldecoderPipe } from './pipes/htmldecoder.pipe';

export function tokenGetter() {
  return localStorage.getItem("token");
}

@NgModule({
  declarations: [
    AppComponent,
    NaviComponent,
    SliderComponent,
    FooterComponent,
    ProjectsComponent,
    ProjectdetailComponent,
    ProjectFilterPipePipe,
    NgVarDirective,
    GaleryFilterPipe,
    AdminComponent,
    AdminAddComponent,
    AdminListAndDeleteComponent,
    AdminListAndDeleteSummaryProjectsComponent,
    AdminListAndDeleteSummaryGalleriesComponent,
    LoginComponent,
    AdminListAndDeleteSummaryCategoriesComponent,
    AdminListAndDeleteSummaryVirtualToursComponent,
    AdminUpdateComponent,
    ForgotPasswordComponent,
    NotfoundpageComponent,
    GeneralSettingsComponent,
    IframeComponent,
    CustomerRouterComponent,
    ReadonlyckeComponent,
    AdminListAndDeleteSummaryPojectdetailsComponent,
    AboutComponent,
    ContactComponent,
    TestpageComponent,
    IframeLayoutComponent,
    HtmldecoderPipe,
   
   
  
  ],
  imports: [
    BrowserModule,
    NgxCaptchaModule,
    AppRoutingModule,
    LightboxModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    FormsModule,
    MatSelectModule,
    MatDialogModule,
    MatButtonModule,
    MatTabsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    CKEditorModule,
    EditorModule

  ],
  providers: [
    {provide:HTTP_INTERCEPTORS,useClass:AuthInterceptor,multi:true},
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}}
  ],
  bootstrap: [AppComponent]

})
export class AppModule { }
