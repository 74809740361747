import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { ListResponseModel } from '../models/listResponseModel';
import { Project } from '../models/project';
import { SingleResponseModel } from '../models/singleResponseModel';
import { ResponseModel } from '../models/responseModel';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  public projects: Project[];
  public sliderProjects: Project[];
  constructor(private httpClient: HttpClient) {
    this.getProjectList();
    this.getProjectListVitrin();
  }
  getProjectList(): boolean {
    if (
      this.httpClient
        .get<Project[]>(environment.getApiUrl + '/projects/getall')
        .subscribe((response) => {
          this.projects = response;
        })
    ) {
      return true;
    } else {
      return false;
    }
  }
  getProjectsListByTop(top: number) {
    this.httpClient
      .get<Project[]>(
        environment.getApiUrl + '/projects/getprojectsbytop?top=' + top
      )
      .subscribe((response) => {
        this.sliderProjects = response;
      });
  }
  getProjectListVitrin() {
    this.httpClient
      .get<Project[]>(environment.getApiUrl + '/projects/getprojectlistvitrin')
      .subscribe((response) => {
        this.sliderProjects = response;
      });
  }

  getProjectById(id: number): Observable<Project> {
    return this.httpClient.get<Project>(
      environment.getApiUrl + '/projects/getbyid?projectId=' + id
    );
  }
  addProject(project: Project, file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('projectName', project.projectName);
    formData.append('Image', file);
    formData.append('projectDescription', project.projectDescription);
    formData.append('isVitrin', project.isVitrin);
    formData.append('order', project.order);
    return this.httpClient.post(
      environment.getApiUrl + '/projects/',
      formData,
      { responseType: 'text' }
    );
  }
  getProjectColumnNames(): Observable<string[]> {
    return this.httpClient.get<string[]>(
      environment.getApiUrl + '/projects/getprojectcolumnnames'
    );
  }
  updateProject(project: Project, file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('projectName', project.projectName);
    formData.append('projectPicture', project.projectPicture);
    formData.append('projectId', project.projectId.toString());
    formData.append('Image', file);
    formData.append('isVitrin', project.isVitrin);
    formData.append('order', project.order);
    formData.append('projectDescription', project.projectDescription);
    return this.httpClient.put(environment.getApiUrl + '/projects/', formData, {
      responseType: 'text',
    });
  }

  deleteProject(id: number) {
    return this.httpClient.request(
      'delete',
      environment.getApiUrl + '/projects/',
      { body: { projectId: id } }
    );
  }
  shareVirtualTours(projectId: number): Observable<string> {
    const formData: FormData = new FormData();
    formData.append('projectID', String(projectId));
    return this.httpClient.post(
      environment.getApiUrl + '/Projects/virtualshare',
      formData,
      { responseType: 'text' }
    );
  }
}
