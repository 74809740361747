import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
//lightbox
import { Lightbox } from 'ngx-lightbox';
import { ToastrService } from 'ngx-toastr';
import { Category } from 'src/app/models/category';
import { Gallery } from 'src/app/models/gallery';
import { Project } from 'src/app/models/project';
import { ProjectDetailPage } from 'src/app/models/projectDetailPage';
import { VirtualTour } from 'src/app/models/virtualTour';
import { VirtualTourDto } from 'src/app/models/virtualtourDto';
import { AuthService } from 'src/app/services/auth.service';
import { CategoryService } from 'src/app/services/category.service';
import { GalleryService } from 'src/app/services/gallery.service';
import { ProjectService } from 'src/app/services/project.service';
import { ProjectdetailService } from 'src/app/services/projectdetail.service';
import { VirtualTourService } from 'src/app/services/virtual-tour.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-projectdetail',
  templateUrl: './projectdetail.component.html',
  styleUrls: ['./projectdetail.component.css'],
})
export class ProjectdetailComponent implements OnInit {
  project: Project;
  virtualTours: VirtualTour[];
  virtualToursDto: VirtualTourDto[];
  projectDetail: ProjectDetailPage;
  album: any = [];

  path = environment.getUrl;
  categoryId = 0;
  categoryBase = 0;

  public htmldata: string="";
  public readonly: boolean = true;
  public config = { toolbar: { items: [] } };

  constructor(
    private _lightbox: Lightbox,
    private projectService: ProjectService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    public virtualTourService: VirtualTourService,
    public categoryService: CategoryService,
    private galleryService: GalleryService,
    private toastr: ToastrService,
    private route: Router,
    private projectDetailService: ProjectdetailService
  ) {
    // setInterval(() => {
    //   console.log(this.htmldata);
    // }, 2000);
  }

  open(index: number): void {
    this._lightbox.open(this.album, index);
  }
  close(): void {
    this._lightbox.close();
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      if (params['projectId']) {
        this.getProjectById(params['projectId']);
        this.getProjectDetailPagebyProjectID(params['projectId']);
        //this.getCategoriesBasebyProjectId(params['projectId']);
        //this.getCategoriesbyProjectId(params['projectId']);
        this.getVirtualToursDTOByProjectId(params['projectId']);
        if (this.authService.loggedIn()) {
          //this.getGalleries(params['projectId'],this.authService.userId);
          //this.getVirtualTourByUserId(params['projectId']);
          //this.getVirtualTourByProjectId(params['projectId']);
        } else {
          //this.getGalleries(params['projectId'],0);
        }
      }
    });
  }
  getProjectDetailPagebyProjectID(projectId: number) {
    this.projectDetailService
      .getProjectDetailPagebyProjectID(projectId)
      .subscribe((response) => {
        this.projectDetail = response;
        if (this.projectDetail) {
          this.htmldata = this.projectDetail.content;
        }
        else{
          this.htmldata= 'İçerik Yok!';
        }
      });
  }
  getProjectById(projectId: number) {
    this.projectService.getProjectById(projectId).subscribe((response) => {
      if (response) {
        this.project = response;
      } else {
        this.route.navigateByUrl('/404');
      }
    });
  }
  getCategoriesbyProjectId(projectId: number) {
    this.categoryService.getCategoriesFilter(projectId);
  }
  getCategoriesBasebyProjectId(projectId: number) {
    this.categoryService.getCategoriesBaseFilter(projectId);
  }
  getVirtualTourByProjectId(projectId: number) {
    this.virtualTourService.getVirtualTourByProjectId(projectId).subscribe(
      (response) => {
        this.virtualTours = response;
      },
      (errorResponse) => {
        if ((errorResponse.status = 401)) {
        } else {
          this.toastr.error(errorResponse.message, errorResponse.name);
        }
      }
    );
  }
  getVirtualToursDTOByProjectId(projectId: number){
    this.virtualTourService.getVirtualToursDTOByProjectId(projectId).subscribe( (response) => {
      this.virtualToursDto = response;
    },
    (errorResponse) => {
      if ((errorResponse.status = 401)) {
      } else {
        this.toastr.error(errorResponse.message, errorResponse.name);
      }
    });
  }
  getVirtualTourByUserId(projectId: number) {
    this.virtualTourService
      .getVirtualTourListDtobyUser(this.authService.userId, projectId)
      .subscribe((response) => {
        this.virtualToursDto = response;
      });
  }

  getGalleries(projectId: number, userId: number) {
    this.galleryService.getGalleriesByClaim(projectId, userId);
    this.album = this.galleryService.album;
  }
  getGalleriesbyFilter() {
    this.galleryService.getGalleriesbyFilter(
      this.categoryId,
      this.categoryBase
    );
  }
  getVirtualTourDTO(){
    this.virtualTourService.getVirtualTourListDto();
    this.virtualToursDto=this.virtualTourService.virtualToursDto;
  }
}
