import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Gallery } from '../models/gallery';
import { ListResponseModel } from '../models/listResponseModel';
import { ProjectDetailPage } from '../models/projectDetailPage';
import { ProjectDetailPageDto } from '../models/projectDetailPageDto';

@Injectable({
  providedIn: 'root'
})
export class ProjectdetailService {
 pdpage:ProjectDetailPage;
 pdpageDto:ProjectDetailPageDto[];
  constructor(private httpClient: HttpClient) {
    this.getAllProjectDetailsDto();
   }
  getProjectDetailPagebyProjectID(projectId:number): Observable<ProjectDetailPage> {
    return this.httpClient.get<ProjectDetailPage>(
      environment.getApiUrl + '/ProjectDetailPages/getbyprojectid?projectID='+projectId
    );
    
  }

  addProjectDetailPage(data:ProjectDetailPage):Observable<any>{
   
    return this.httpClient.post(
      environment.getApiUrl + '/ProjectDetailPages/',
      data,
      { responseType: 'text' }
    );
  }
  updateProjectDetailPage(data:ProjectDetailPage):Observable<any>
  {
    return this.httpClient.put(environment.getApiUrl + '/ProjectDetailPages/', data, { responseType: 'text' });
  }
  getAllProjectDetails():Observable<ProjectDetailPage[]>
  {
    return this.httpClient.get<ProjectDetailPage[]>(
      environment.getApiUrl + '/ProjectDetailPages/getall'
    );
  }
  getAllProjectDetailsDto()
  {
    this.httpClient.get<ProjectDetailPageDto[]>(
      environment.getApiUrl + '/ProjectDetailPages/getlistdto'
    ).subscribe((response)=>{this.pdpageDto=response});
  }
  deleteProjectDetailPage(id:number){
    return this.httpClient.request(
      'delete',
      environment.getApiUrl + '/ProjectDetailPages/',
      { body: { projectDetailPageID: id } }
    );
  }

}
