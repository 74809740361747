<div class="container">
  <div class="row mt-2 mb-4">
    <div class="col-sm-6 mb-2">
      <div class="card">
        <div class="card-body">
          <h1 class="card-title">PROJE</h1>
          <p class="card-text">
            Bu bölümden proje güncelleştirmesi yapabilirsiniz.
          </p>
          <form [formGroup]="projectUpdateForm" class="was-validated">
            <div class="form-group">
              <mat-form-field appearance="fill" class="mr-4">
                <mat-label>Proje Seçiniz</mat-label>
                <mat-select id="projectId" formControlName="projectId" required>
                  <mat-option
                    *ngFor="let x of projectService.projects"
                    [value]="x.projectId"
                    (click)="selectedProject(x.projectId)"
                    >{{ x.projectName }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <img
                [src]="input_projectPictureimg"
                class="img-thumbnail"
                style="max-width: 150px; max-height: 150px"
              />
            </div>
            <div class="form-group">
              <label for="projectName">Proje Adı*</label>
              <input
                type="text"
                [(ngModel)]="input_projectName"
                class="form-control"
                id="projectName"
                formControlName="projectName"
                maxlength="50"
                aria-describedby="projectHelp"
                required
              />
              <small id="projectHelp" class="form-text text-muted"
                >50 Karakteri geçmeyecek şekilde proje adı yazın.</small
              >
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                formControlName="isVitrin"
                id="isVitrin"
                
                [checked]="input_isVitrin=='true'"
                
              />
              <label
                class="form-check-label text-primary"
                for="flexCheckDefault"
              >
                Vitrine Ekle! (Seçilen Proje Ana Sayfada Gözükür)
              </label>
            </div>
           
            <div class="form-group">
              <label for="projectDescription">Açıklama</label>
              <app-readonlycke
              [(ngModel)]="input_projectDescription"
              formControlName="projectDescription"
            ></app-readonlycke>
            
              <small id="projectDescriptionHelp" class="form-text text-muted"
                >300 Karakteri geçmeyecek şekilde proje açıklaması yazın.</small
              >
            </div>
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">Resim</span>
                </div>
                
                <div class="custom-file file-box-design">
                  <input
                    type="file"
                    (change)="selectFiles($event)"
                    class="custom-file-input"
                    id="projectPicture"
                    accept="image/*"
                    formControlName="projectPicture"
                    
                  />
                 <input
                    class="custom-file-label img-btn-content"
                    for="projectPicture"
                    value="{{ imgBtnText }}"
                  />
                </div>
              </div>
              <div class="form-outline col-md-3">
                <label class="form-label" for="order">Proje Sırası*</label>
                <input
                  type="number"
                  id="order"
                  formControlName="order"
                  [(ngModel)]="input_order"
                  class="form-control"
                  [value]="input_order"
                  min="1"
                />
                
              </div>
            </div>
            
          </form>
          <button class="btn btn-warning" (click)="updateProject()">
            Güncelle
          </button>
        </div>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="card">
        <div class="card-body">
          <h1 class="card-title">SANAL TUR</h1>
          <p class="card-text">Bu bölümde sanal turu güncelleyebilirsiniz.</p>
          <form [formGroup]="virtualUpdateForm" class="was-validated">
            <div class="form-group">
              <div class="form-group">
                <mat-form-field appearance="fill">
                  <mat-label>Sanal Tur Seçiniz</mat-label>
                  <mat-select
                    id="virtualTourId"
                    formControlName="virtualTourId"
                    required
                  >
                    <mat-option
                      *ngFor="let x of virtualTourService.virtualTours"
                      [value]="x.virtualTourId"
                      (click)="selectedVirtualTour(x)"
                      >{{ x.name }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
                <img
                [src]="input_virtualTourPicture"
                class="img-thumbnail"
                style="max-width: 150px; max-height: 150px"
              />
              </div>
           
              <label for="VirtualTourName">Sanal Tur Adı</label>
              <input
                type="text"
                class="form-control"
                id="VirtualTourName"
                [(ngModel)]="input_virtualTourName"
                formControlName="name"
                name="VirtualTourName"
                maxlength="50"
                aria-describedby="virtualTourHelp"
              />
              <small id="virtualTourHelp" class="form-text text-muted"
                >50 Karakteri geçmeyecek şekilde sanal tur adı yazın.</small
              >
            </div>
           
            <div class="form-group">
              <label for="VirtualTourDescription">Açıklama</label>
              <app-readonlycke
              [(ngModel)]="input_virtualTourDescription"
              formControlName="description"
            ></app-readonlycke>
            
              <small
                id="VirtualTourDescriptionHelp"
                class="form-text text-muted"
                >300 Karakteri geçmeyecek şekilde sanal tur açıklaması
                yazın.</small
              >
            </div>
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="CoverPhoto">Resim</span>
                </div>
                <div class="custom-file file-box-design">
                  <input
                    type="file"
                    class="custom-file-input"
                    id="CoverPhoto"
                    formControlName="pictureThumbnail"
                    (change)="selectVirtoulTourCoverPhoto($event)"
                    
                    aria-describedby="inputGroupFileAddon01"
                  />
                  <input
                    class="custom-file-label img-btn-content"
                    for="CoverPhoto"
                    value="{{ imgBtnTextVirtual }}"
                  />
                </div>
               
              </div>
            </div>
            <div class="form-group">
              <mat-form-field appearance="fill">
                <mat-label>Proje Seçiniz</mat-label>
                <mat-select
                  id="ProjectId"
                  [(ngModel)]="input_virtualTourProjectId"
                  name="ProjectId"
                  formControlName="projectId"
                  required
                >
                  <mat-option
                    *ngFor="let x of projectService.projects"
                    [value]="x.projectId"
                    
                    >{{ x.projectName }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <small id="categoryHelp" class="form-text text-muted"
                >Lütfen bağlı olacağı projeyi seçiniz.</small
              >
            </div>
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroupFileAddon01"
                    >Sanal Tur Dosyası</span
                  >
                </div>
                <div class="custom-file">
                  <input
                    type="file"
                    class="custom-file-input is-invalid"
                    name="files[]"
                    id="VirtualTourFolders"
                    formControlName="dataUrl"
                    (change)="selectFiles($event)"
                    multiple
                    accept="*"
                    directory=""
                    webkitdirectory=""
                    moxdirectory=""
                    aria-describedby="inputGroupFileAddon01"
                  />

                  <label class="custom-file-label" for="inputGroupFile01"
                    >Sanal Tur Seçin</label
                  >
                </div>
              </div>
            </div>
            <div class="progress form-group" *ngIf="progress > 0">
              <div
                class="progress-bar progress-bar-striped bg-success"
                role="progressbar"
                [style.width.%]="progress"
              >
                {{ progress }}%
              </div>
            </div>
            <button class="btn btn-warning" (click)="uploadVirtualTourFiles()">
              Güncelle
            </button>
          </form>
        </div>
      </div>
    </div>
    <!-- <div class="col-sm-6">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">KATEGORİ</h5>
          <p class="card-text">Bu bölümde kategorinizi güncelleyebilirsiniz.</p>
          <form [formGroup]="categoryUpdateForm" class="was-validated">
            <div class="form-group">
              <mat-form-field appearance="fill">
                <mat-label>Kategori Seçiniz</mat-label>
                <mat-select
                  id="categoryId"
                  formControlName="categoryId"
                  required
                >
                  <mat-option
                    *ngFor="let x of categoryService.categoriesAll"
                    [value]="x.categoryId"
                    (click)="selectedCategory(x.categoryId)"
                    >{{ x.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <div class="form-group">
              <label for="categoryName">Kategori Adı*</label>
              <input
                type="text"
                class="form-control"
                id="Name"
                formControlName="name"
                [(ngModel)]="input_categoryName"
                required
                maxlength="50"
                aria-describedby="categoryHelp"
              />
              <small id="categoryHelp" class="form-text text-muted"
                >50 Karakteri geçmeyecek şekilde kategori ismi yazın.</small
              >
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value="0"
                  id="Pid"
                  formControlName="categoryPid"
                />
                <label class="form-check-label" for="mainCategory">
                  Ana Kategori Olarak Belirle
                </label>
              </div>
            </div>
            <button (click)="updateCategory()" class="btn btn-warning">
              Güncelle
            </button>
          </form>
        </div>
      </div>
    </div> -->
  </div>

  <div class="row mt-2 mb-2">
    <div class="col-sm-6 col-md-12 col-lg-12 mb-2">
      <div class="card">
        <form enctype="multipart/form-data"></form>
        <div class="card-body">
          <h1 class="card-title">İÇERİK</h1>
          <p class="card-text">Bu bölümden içerik güncelleyebilirsiniz.</p>
          <form [formGroup]="projectDetailUpdateForm" class="was-validated">
            <!-- <div class="form-check mb-4">
              <input
                class="form-check-input"
                type="checkbox"
                formControlName="isOnlyAdmin"
                id="adminCheck"
              />
              <label class="form-check-label" for="adminCheck">
                Sadece Yönetici Görebilsin!
              </label>
            </div> -->
            <div class="form-group">
              <mat-form-field appearance="fill">
                <mat-label>Proje Seçiniz*</mat-label>
                <mat-select formControlName="projectId" id="projectId">
                  <mat-option
                    *ngFor="let project of projectService.projects"
                    [value]="project.projectId"
                    (click)="selectedProjectDetail(project.projectId)"
                    >{{ project.projectName }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <small id="categoryHelp" class="form-text text-muted"
                >Lütfen bağlı olacağı projeyi seçiniz.</small
              >
            </div>
            <!-- <div class="form-group">
              <mat-form-field appearance="fill">
                <mat-label>Kategori Seçiniz*</mat-label>
                <mat-select
                  formControlName="categoryId"
                  id="categoryId"
                  required
                >
                  <mat-option
                    *ngFor="let category of categoryService.categoriesFilterAll"
                    [value]="category.categoryId"
                    >{{ category.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <small id="categoryHelp" class="form-text text-muted"
                >Lütfen bağlı olacağı kategori seçiniz.</small
              >
            </div> -->
            
            <!-- <div class="form-group">
              <mat-form-field appearance="fill">
                <mat-label>Mimari Seçiniz*</mat-label>
                <mat-select
                  formControlName="categoryBase"
                  id="categoryBase"
                  required
                >
                  <mat-option
                    *ngFor="
                      let category of categoryService.categoriesBaseFilterAll
                    "
                    [value]="category.categoryId"
                    >{{ category.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <small id="categoryHelp" class="form-text text-muted"
                >Lütfen bağlı olacağı mimariyi seçiniz.</small
              >
            </div> -->

            <!-- <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroupFileAddon01"
                    >Resimler*</span
                  >
                </div>
                <div class="custom-file">
                  <input
                    type="file"
                    class="custom-file-input"
                    id="galleryPicture"
                    multiple
                    accept="image/*"
                    (change)="selectFiles($event)"
                    formControlName="galleryPicture"
                    id="galleryPicture"
                    required
                  />
                  <label class="custom-file-label" for="galleryPicture"
                    >Resimleri Yükleyin</label
                  >
                </div>
              </div>
            </div> -->
            <app-readonlycke  [(ngModel)]="htmldata" formControlName="content"></app-readonlycke>
            <button (click)="updateProjectDetail()" [disabled]="isUpdateButtonProjectDetail" class="btn btn-warning">
              Güncelle
            </button>
          </form>
         
        </div>
      </div>
    </div>
    
  </div>
</div>
