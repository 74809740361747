import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Category } from 'src/app/models/category';
import { Gallery } from 'src/app/models/gallery';
import { CategoryService } from 'src/app/services/category.service';
import { GalleryService } from 'src/app/services/gallery.service';

@Component({
  selector: 'app-admin-list-and-delete-summary-categories',
  templateUrl: './admin-list-and-delete-summary-categories.component.html',
  styleUrls: ['./admin-list-and-delete-summary-categories.component.css'],
})
export class AdminListAndDeleteSummaryCategoriesComponent implements OnInit {
  constructor(
    public categoryService: CategoryService,
    private toastr: ToastrService,
    private dialog: MatDialog
  ) {}
  categories: Category[];

  ngOnInit(): void {}

  deleteCategory(id: number) {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '350px',
      height: '200px',
      data: {},
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.categoryService.deleteCategory(id).subscribe(
          (response) => {},
          (errorResponse) => {
            this.toastr.success(errorResponse.error.text, 'Başarılı'),
             this.categoryService.getCategoryList(),
             this.categoryService.getCategoriesBaseFilterAll(),
             this.categoryService.getCategoriesFilterAll()
          }
        );
      } else {
        this.toastr.warning('Silme işlemi iptal edildi', 'Uyarı');
      }
    });

    //burada kaldık
  }
}
@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
})
export class DialogOverviewExampleDialog {
  constructor(public dialogRef: MatDialogRef<DialogOverviewExampleDialog>) {}

  onNoClick(isOk?: boolean): void {
    this.dialogRef.close(isOk);
  }
}
