import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-customer-router',
  templateUrl: './customer-router.component.html',
  styleUrls: ['./customer-router.component.css'],
})
export class CustomerRouterComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private adminService: AdminService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      if (params['customUrl'] && params['projectId']) {
        this.solveUrl(params['customUrl'], params['projectId']);
      }
    });
  }

  solveUrl(url: string, projectId: number) {
    this.adminService.isLinkActive(url).subscribe(
      (response) => {
        let urlArray = url.split('6cY24fkq3L');
        let _email = urlArray[0];
        let _password = urlArray[1];

        let loginuser = Object.assign({ email: _email, password: _password });

        if (
          this.authService.loggedIn ||
          this.authService.isAuthenticated ||
          this.authService.isLoggin
        ) {
          this.authService.logOut();
          this.authService.claims = [];
        }
        this.authService.loginLink(loginuser, projectId);
      },
      (errorResponse) => {
        this.router.navigate(['']);
      }
    );
  }
}
