import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Mail } from 'src/app/models/mail';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
})
export class ContactComponent implements OnInit {
  mailFormGroup: FormGroup;

  siteKey: string = '6LcU1HkcAAAAAJYBMLtDXXhD5a2nMPfCjiLvk-10';
  constructor(
    private mailFormBuilder: FormBuilder,
    private toastr: ToastrService,
    private authservice: AuthService
  ) {}

  ngOnInit(): void {
    this.createMailForm();
  }
  createMailForm() {
    this.mailFormGroup = this.mailFormBuilder.group({
      name: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
        ]),
      ],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      subject: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
        ]),
      ],
      content: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(300),
        ]),
      ],
      recaptcha: ['', Validators.required],
    });
  }
  sendUserMail() {
    if (this.mailFormGroup.valid) {
      let mailModel: Mail = Object.assign({}, this.mailFormGroup.value);
      mailModel.verification = 'A4n4-izmir';

      this.authservice.sendUserEmail(mailModel).subscribe((response) => {
        this.toastr.success('Mail Gönderildi', 'Başarılı');
        window.location.reload();
      });
    } else {
      this.toastr.error('* Zorunlu Alanları Doldurunuz', 'Hata');
    }
  }
  resetSecurity() {}
  makeRandom(lengthOfCode: number, possible: string) {
    let text = '';
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }
}
