import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Project } from 'src/app/models/project';
import { ProjectService } from 'src/app/services/project.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-admin-list-and-delete-summary-projects',
  templateUrl: './admin-list-and-delete-summary-projects.component.html',
  styleUrls: ['./admin-list-and-delete-summary-projects.component.css'],
})
export class AdminListAndDeleteSummaryProjectsComponent implements OnInit {
  path = environment.getUrl;
  shareUrl="";

  constructor(
    public projectService: ProjectService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private router:Router
  ) {}

  ngOnInit(): void {}
  getProjects() {
    this.projectService.getProjectList();
  }

  deleteProject(projectId: number) {

    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '400px',
      height: '300px',
      data: {title:"Silme işlemi Yapacaksınız!",
              text:"Yapmış olacağınız işlem sonucunda bağlı olan görseller ve sanal turlar da silinecektir.",
              buttonOkText:"Biliyorum, sil",
              buttonCancelText:"Vazgeç",

            },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.projectService.deleteProject(projectId).subscribe(
          (response) => {},
          (errorResponse) => {
            this.toastr.success(errorResponse.error.text, 'Başarılı'),
              this.getProjects();
          }
        );
      } else {
        this.toastr.warning('Silme işlemi iptal edildi', 'Uyarı');
      }
    });
  }
  shareVirtualTours(projectId: number)
  {
    this.projectService.shareVirtualTours(projectId).subscribe(response=>{
      this.shareUrl=environment.getsiteUrl+"sanalturlar/musteri/"+response+"/"+projectId;
      const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
        width: '400px',
        height: '300px',
        data: {title:"Müşteriye Paylaşılacak Link!",
        text:this.shareUrl,
        buttonOkText:"Tamam",
        buttonCancelText:"Tamam",},
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {

        } else {

        }
      });
    },errorResponse=>{
      localStorage.clear();
      this.router.navigateByUrl('/login');
      this.toastr.warning(errorResponse.message,"Hata");
    });



  }
}
export interface DialogData {
  title:string;
  text:string;
  buttonOkText:string;
  buttonCancelText:string;
}
@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
})
export class DialogOverviewExampleDialog {

  constructor(public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,@Inject(MAT_DIALOG_DATA) public data: DialogData) {

  }

  onNoClick(isOk?: boolean): void {
    this.dialogRef.close(isOk);
  }
}
