<app-navi></app-navi>
<div class="container margin_top_70">
  <div class="tz-gallery text-center ">
    <h1 class="text-center">Projelerimiz</h1>
    <h1 class="slogan">Tasarımın Sanatsal Hali...</h1>
  </div>
</div>
<div class="album py-5 bg-light">
  <div class="container-fluid">
    <div class="d-flex justify-content-center" *ngIf="dataLoaded == false">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
   
      <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-3">
          <div class="col" *ngFor="let project of projectService.projects">
              <div class="card shadow-sm">
                <img class="bd-placeholder-img card-img-top" src="{{ path }}{{ project.projectThumbnail }}" width="100%" height="250"/>
              <div class="caption" routerLink="/projeler/proje/{{ project.projectId }}"
              (click)="setCurrentProject(project)">
                <h2 class="baslik">{{ project.projectName }}</h2>
                <div class="project_description" [innerHTML]="project.projectDescription| htmldecoder"> </div>
              </div>
              </div>
            </div>
          
      </div>
  </div>
</div>
<app-footer></app-footer>
