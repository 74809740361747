
<table class="table table-striped mt-3">
    <thead>
      <tr>
       <th scope="col">#</th>
       <th scope="col">Kategori Adı</th>
       <th scope="col">Pid</th>
       
       <!--<th scope="col" *ngFor="let c of columnNames">{{c}}</th>-->
       <th scope="col">İşlem</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let x of categoryService.categoriesAll">
        <th scope="row">{{x.categoryId}}</th>
        <td>{{x.name}}</td>
        <td>{{x.pid}}</td>
        <td><button type="button" (click)="deleteCategory(x.categoryId)" class="btn btn-danger btn-sm">Sil</button></td>
      </tr>
      
    </tbody>
  </table>

