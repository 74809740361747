<app-navi></app-navi>
<main class='container'>
    <span class='particle'>4</span>
    <span class='particle'>4</span>
    <span class='particle'>4</span>
    <span class='particle'>4</span>
    <span class='particle'>4</span>
    <span class='particle'>4</span>
    <span class='particle'>4</span>
    <span class='particle'>4</span>
    <span class='particle'>4</span>
    <span class='particle'>4</span>
    <span class='particle'>4</span>
    <span class='particle'>4</span>
    <span class='particle'>4</span>
    <span class='particle'>4</span>
    <span class='particle'>4</span>
    <span class='particle'>4</span>
    <span class='particle'>4</span>
    <span class='particle'>4</span>
    <span class='particle'>4</span>
    <span class='particle'>4</span>
    <span class='particle'>4</span>
    <span class='particle'>4</span>
    <span class='particle'>4</span>
    <span class='particle'>4</span>
    <span class='particle'>4</span>
    <span class='particle'>4</span>
    <span class='particle'>4</span>
    <span class='particle'>4</span>
    <span class='particle'>4</span>
    <span class='particle'>4</span>
    <span class='particle'>4</span>
    <span class='particle'>4</span>
    <span class='particle'>4</span>
    <span class='particle'>4</span>
    <span class='particle'>4</span>
    <span class='particle'>4</span>
    <span class='particle'>4</span>
    <span class='particle'>4</span>
    <span class='particle'>4</span>
    <span class='particle'>4</span>
    <span class='particle'>0</span>
    <span class='particle'>0</span>
    <span class='particle'>0</span>
    <span class='particle'>0</span>
    <span class='particle'>0</span>
    <span class='particle'>0</span>
    <span class='particle'>0</span>
    <span class='particle'>0</span>
    <span class='particle'>0</span>
    <span class='particle'>0</span>
    <span class='particle'>0</span>
    <span class='particle'>0</span>
    <span class='particle'>0</span>
    <span class='particle'>0</span>
    <span class='particle'>0</span>
    <span class='particle'>0</span>
    <span class='particle'>0</span>
    <span class='particle'>0</span>
    <span class='particle'>0</span>
    <span class='particle'>0</span>
    <span class='particle'>0</span>
    <span class='particle'>0</span>
    <span class='particle'>0</span>
    <span class='particle'>0</span>
    <span class='particle'>0</span>
    <span class='particle'>0</span>
    <span class='particle'>0</span>
    <span class='particle'>0</span>
    <span class='particle'>0</span>
    <span class='particle'>0</span>
    <span class='particle'>0</span>
    <span class='particle'>0</span>
    <span class='particle'>0</span>
    <span class='particle'>0</span>
    <span class='particle'>0</span>
    <span class='particle'>0</span>
    <span class='particle'>0</span>
    <span class='particle'>0</span>
    <span class='particle'>0</span>
    <span class='particle'>0</span>
    <article class='content'>
      <p><strong>Üzülmeyin</strong>, aradığınız sayfa yoksa aramadığınız kadar projelerimiz var.</p>
      <p>Hayallerinizi bize anlatın, <strong>sanata</strong> çevirelim.</p>
      <p>
        <button routerLink="/projeler">Tüm Projeleri Göster</button>
      </p>
    </article>
  </main>
  <app-footer></app-footer>