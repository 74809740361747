import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoginUser } from '../models/login-user';
import { Mail } from '../models/mail';
import { TokenModel } from '../models/token-model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userName: string;
  userId: number;
  isLoggin: boolean;
  decodedToken: any;
  userToken: string;
  claims: string[];
  jwtHelper: JwtHelperService = new JwtHelperService();
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private toastr: ToastrService
  ) {}

  login(user: LoginUser) {
    return this.httpClient
      .post<TokenModel>(environment.getApiUrl + '/auth/login', user)
      .subscribe(
        (response) => {
          if (response.success) {
            localStorage.setItem('token', response.data.token);

            this.claims = response.data.claims;
            var decode = this.jwtHelper.decodeToken(
              localStorage.getItem('token')
            );

            var propUserName = Object.keys(decode).filter((x) =>
              x.endsWith('/name')
            )[0];
            this.userName = decode[propUserName];
            this.router.navigateByUrl('/admin');
            this.toastr.success(
              response.message + ' ' + this.userName,
              'Başarılı'
            );
          } else {
            this.router.navigateByUrl('/login');
          }
        },
        (errorResponse) => {
          this.toastr.error(errorResponse.error, 'Başarısız');
        }
      );
  }
  loginLink(user: LoginUser, projectId: number) {
    return this.httpClient
      .post<TokenModel>(environment.getApiUrl + '/auth/login', user)
      .subscribe(
        (response) => {
          if (response.success) {
            localStorage.setItem('token', response.data.token);

            this.claims = response.data.claims;
            var decode = this.jwtHelper.decodeToken(
              localStorage.getItem('token')
            );
            var propUserName = Object.keys(decode).filter((x) =>
              x.endsWith('/name')
            )[0];
            this.userName = decode[propUserName];
            this.router.navigateByUrl('/projeler/proje/' + projectId);
            this.toastr.success(
              response.message + ' ' + this.userName,
              'Başarılı'
            );
          } else {
            this.router.navigateByUrl('/login');
          }
        },
        (errorResponse) => {
          this.toastr.error(errorResponse.error, 'Başarısız');
        }
      );
  }
  getUserName(): string {
    return this.userName;
  }
  setClaims() {
    if (
      (this.claims == undefined || this.claims.length == 0) &&
      localStorage.getItem('token') != null &&
      this.loggedIn()
    ) {
      this.httpClient
        .get<string[]>(
          environment.getApiUrl + '/OperationClaims/getuserclaimsfromcache'
        )
        .subscribe((data) => {
          this.claims = data;
        },errorResponse=>{
          localStorage.removeItem('token');
          this.router.navigateByUrl('/login');
          this.toastr.warning(errorResponse.message,"Hata");
        });

      var token = localStorage.getItem('token');
      var decode = this.jwtHelper.decodeToken(token);

      var propUserName = Object.keys(decode).filter((x) =>
        x.endsWith('/name')
      )[0];
      this.userName = decode[propUserName];
    }
  }
  logOut() {
    localStorage.removeItem('token');
    this.claims = [];

  }

  loggedIn(): boolean {
    let isExpired = this.jwtHelper.isTokenExpired(
      localStorage.getItem('token')
    );
    if (!isExpired) {
      var decode = this.jwtHelper.decodeToken(localStorage.getItem('token'));
      var propUserId = Object.keys(decode).filter((x) =>
        x.search('nameidentifier')
      )[0];

      this.userId = decode[propUserId];
    }
    return !isExpired;
  }

  getCurrentUserId(): string {
    let decode = this.jwtHelper.decodeToken(localStorage.getItem('token'));
    var propUserId = Object.keys(decode).filter((x) =>
      x.search('nameidentifier')
    )[0];
    let cc = decode[propUserId];
    return cc;
  }

  claimGuard(claim: string): boolean {
    if(this.isAuthenticated()&& this.loggedIn()){

      this.setClaims();
      var check = this.claims.some(function (item) {

        return item == claim;
      });
      return check;
    }
    else{
      this.router.navigateByUrl('/login');
      return false;
    }


  }

  isAuthenticated() {
    if (localStorage.getItem('token')) {
      return true;
    } else {
      return false;
    }
  }
  forgotPassword(sendForgotPasswordInfo: any): Observable<any> {
    console.log(sendForgotPasswordInfo);
    return this.httpClient.put(
      environment.getApiUrl + '/auth/forgotpassword',
      sendForgotPasswordInfo,
      { responseType: 'json' }
    );
  }
  sendEmail(sendMailModel: any): Observable<any> {
    return this.httpClient.put(
      environment.getApiUrl + '/auth/sendverificationcode',
      sendMailModel,
      { responseType: 'json' }
    );
  }
  sendUserEmail(sendMailModel: Mail): Observable<any> {
    return this.httpClient.post(
      environment.getApiUrl + '/Mail',
      sendMailModel,
      { responseType: 'text' }
    );
  }
  changePassword(passwordModel: any): Observable<any> {
    return this.httpClient.put(
      environment.getApiUrl + '/auth/changeuserpassword',
      passwordModel,
      { responseType: 'json' }
    );
  }

}
