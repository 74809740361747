import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-iframe-layout',
  templateUrl: './iframe-layout.component.html',
  styleUrls: ['./iframe-layout.component.css']
})
export class IframeLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
