import { Injectable } from '@angular/core';
import{HttpClient} from "@angular/common/http";
import { Observable } from 'rxjs/Observable';
import { Category } from '../models/category';
import { ListResponseModel } from '../models/listResponseModel';
import { ResponseModel } from '../models/responseModel';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  public categoriesBaseFilter:Category[];
  public categoriesBaseFilterAll:Category[];
  public categoriesFilter:Category[];
  public categoriesFilterAll:Category[];
  public categoriesAll:Category[];
  constructor(private httpClient:HttpClient) {
    this.getCategoryList();
   }

  getCategoryList() {

    this.httpClient.get<Category[]>(environment.getApiUrl + '/categories/getall').subscribe(response=>{this.categoriesAll=response})
  }
  getCategoriesBaseFilter(projectId:number) {
    this.httpClient.get<Category[]>(environment.getApiUrl + '/categories/getcategoriesbasefilter?projectId='+projectId).subscribe((response) => {
      this.categoriesBaseFilter = response
    });
  }
  getCategoriesFilter(projectId:number){

    this.httpClient.get<Category[]>(environment.getApiUrl + '/categories/getcategoriesfilter?projectId='+projectId).subscribe((response) => {
      this.categoriesFilter = response;
    });
  }
  getCategoriesFilterAll(){

    this.httpClient.get<Category[]>(environment.getApiUrl + '/categories/getall').subscribe((response) => {
      this.categoriesFilterAll = response.filter((p) => p.pid != null);
    });
  }
  getCategoriesBaseFilterAll(){

    this.httpClient.get<Category[]>(environment.getApiUrl + '/categories/getall').subscribe((response) => {
      this.categoriesBaseFilterAll = response.filter((p) => p.pid == null);
    });
  }
 
  getCategoryById(id: number): Observable<Category> {
    return this.httpClient.get<Category>(environment.getApiUrl + '/categories/getbyid?categoryId='+id)
  }

  addCategory(category: Category): Observable<any> {

    return this.httpClient.post(environment.getApiUrl + '/categories/', category, { responseType: 'text' });
  }

  updateCategory(category: Category): Observable<any> {
    return this.httpClient.put(environment.getApiUrl + '/categories/', category, { responseType: 'text' });

  }

  deleteCategory(id: number) {
    return this.httpClient.request('delete', environment.getApiUrl + '/categories/', { body: { categoryId: id } });
  }


}
