<app-navi></app-navi>
<div class="contact-bg container-fluid">
  <div class="container-fluid margin_top_70 contact-bg-flu">
    <div class="container">
      <div class="row d-flex justify-content-center">
        <p class="fs-1 fw-bold text-white contact-slogan">
          "Sizinle yeni tasarımlara hazırlanmaya hazırız.
          <em>Kadir Guzin Mimarlık</em> olarak bizi tercih ettiğiniz için teşekkür
          ederiz"
        </p>
      </div>
      <div class="row">
        <div class="col col-md-12 col-lg-6 col-sm-12">
          <div class="col contact-info">
            <h1>İletişim</h1>
            <p>
              <i class="fas fa-map-marker-alt"></i>  Kınıklı Mah. 2591 Sok. Mono İş Merkezi
              No:29 Kat:1 Daire:4 Pamukkale/DENİZLİ
            </p>
            <p><i class="fas fa-phone-alt"></i> +90 (533) 931 20 88</p>
            <p><i class="fas fa-envelope-open"></i> info@kadirguzinmimarlik.com</p>
          </div>
        </div>
        <div class="col col-md-12 col-lg-6 col-sm-12">
          <div class="mb-5">
            <div
              class="card text-end"
              style="border-radius: 0.8rem"
            >
              <div class="card-body">
                <h3 class="card-title">Bize Ulaşın</h3>
                <form [formGroup]="mailFormGroup" class="was-validated">
                  <div class="mb-3">
                    <label for="name" class="form-label">Adınız*</label>

                    <input
                      type="text"
                      class="form-control"
                      id="name"
                      formControlName="name"
                      maxlength="50"
                      minlength="3"
                      required
                    />
                    <small>En az 3 en fazla 50 karakter girebilirsiniz</small>
                  </div>
                  <div class="mb-3">
                    <label for="email" class="form-label"
                      >E-Posta Adresiniz*</label
                    >
                    <input
                      type="email"
                      class="form-control"
                      id="email"
                      placeholder="name@kgdesignmimarlik.com"
                      formControlName="email"
                      required
                    />
                  </div>
                  <div class="mb-3">
                    <label for="subject" class="form-label">Konu*</label>
                    <input
                      type="text"
                      class="form-control"
                      id="subject"
                      placeholder=""
                      maxlength="50"
                      minlength="3"
                      formControlName="subject"
                      required
                    />
                  </div>
                  <div class="mb-3">
                    <label for="content" class="form-label">Mesajınız*</label>
                    <textarea
                      class="form-control"
                      id="content"
                      placeholder="Sizinle çalışmaktan gurur duyarız!"
                      style="height: 150px"
                      maxlength="300"
                      minlength="5"
                      formControlName="content"
                      required
                    ></textarea>
                    <small>En fazla 300 karakter girebilirsiniz</small>
                  </div>
                  <div class="mb-3">
                    <ngx-recaptcha2
                    #captchaElem
                    [siteKey]="siteKey"
                    formControlName="recaptcha"
                  >
                  </ngx-recaptcha2>
                  </div>

                  <button class="btn btn-primary" (click)="sendUserMail()">
                    Gönder
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</div>
<div class="">
  <iframe
    src="https://www.google.com/maps/embed/v1/place?q=37.750530927162735,+29.08468143898222&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
    width="100%"
    height="500"
    style="border: 0"
    allowfullscreen=""
    loading="lazy"
  ></iframe>


</div>
<app-footer></app-footer>
