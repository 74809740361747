
<div class="jumbotron mt-2">
    <h1 class="display-4">Listele ve Sil</h1>
    <p class="lead">Bu sayfada eklediğiniz projeleri, sanal turları, proje içeriğini listeleyip silme işlemi yapabilirsiniz. Uyarılara dikkat etmeyi unutmayın!</p>
    <hr class="my-4">
   
    <p>Aşağıdan listelemek istediğiniz bölümü seçebilirsiniz. </p>
    <div class="form-group">
        <mat-form-field appearance="fill" >
          <mat-label>Listelenecek</mat-label>
          <mat-select id="categoryBase" [(ngModel)]="listName">
            <mat-option  value="project">Projeler</mat-option>
            <mat-option  value="details">Proje İçeriği</mat-option>
            <!-- <mat-option  value="picture">Resimler</mat-option> -->
            <!-- <mat-option  value="categories">Kategoriler</mat-option> -->
            <mat-option  value="virtualTours">Sanal Turlar</mat-option>
          </mat-select>
        </mat-form-field>
        <small id="categoryHelp" class="form-text text-muted"
          >Lütfen Seçim Yapınız</small>
      </div>
  </div>
  <app-admin-list-and-delete-summary-projects *ngIf="listName=='project'"></app-admin-list-and-delete-summary-projects>
  <app-admin-list-and-delete-summary-galleries *ngIf="listName=='picture'"></app-admin-list-and-delete-summary-galleries>
  <!-- <app-admin-list-and-delete-summary-categories *ngIf="listName=='categories'"></app-admin-list-and-delete-summary-categories> -->
  <app-admin-list-and-delete-summary-virtual-tours *ngIf="listName=='virtualTours'"></app-admin-list-and-delete-summary-virtual-tours>
<app-admin-list-and-delete-summary-pojectdetails *ngIf="listName=='details'"></app-admin-list-and-delete-summary-pojectdetails>