import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { VirtualTourDto } from 'src/app/models/virtualtourDto';
import { VirtualTourService } from 'src/app/services/virtual-tour.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-list-and-delete-summary-virtual-tours',
  templateUrl: './admin-list-and-delete-summary-virtual-tours.component.html',
  styleUrls: ['./admin-list-and-delete-summary-virtual-tours.component.css'],
})
export class AdminListAndDeleteSummaryVirtualToursComponent implements OnInit {

  path = environment.getUrl;
  constructor(
    public virtualTourService: VirtualTourService,
    private toastr: ToastrService,
    private router:Router
  ) {}

  ngOnInit(): void {
    this.getVirtualTourList();
  }

  getVirtualTourList() {
    this.virtualTourService.getVirtualTourListDto();
  }
  deleteVirtualTour(id: number) {
    this.toastr.info('Siliniyor', 'Bilgi');
    this.virtualTourService.deleteVirtualTour(id).subscribe(
      (response) => {
        this.toastr.info(response.toString(), 'Bilgi');
      },(errorResponse) => {
        if (errorResponse.status == 200) {
          this.toastr.success('Silme İşlemi Başarılı', 'Bilgi');
          this.getVirtualTourList();
        }
        if(errorResponse.status==401){
          this.toastr.error('Yetkiniz Yok', 'Bilgi');
        }
        if(errorResponse.status == 500){
          localStorage.clear();
          this.router.navigateByUrl('/login');
        }
        else{
          this.toastr.error(errorResponse.message, errorResponse.name);
        }

      }
    );
  }
}
