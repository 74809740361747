import { Component, OnInit } from '@angular/core';
import { Project } from 'src/app/models/project';
import { ProjectService } from 'src/app/services/project.service';
import { FormControl } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-navi',
  templateUrl: './navi.component.html',
  styleUrls: ['./navi.component.css'],
})
export class NaviComponent implements OnInit {
  filterText = '';
  projectId = '';
  selected = 0;
  dataLoaded:boolean=false;
  constructor(
    public projectService: ProjectService,
    public authService: AuthService,
    private router:Router,
    private toastr:ToastrService
  ) {}

  ngOnInit(): void {
    this.getProjects();
    
   
  }
  getProjects() {
    this.projectService.getProjectList();
  }
  currentProject(e: any) {
    this.projectId = e.projectId;
    
    
  }
  logOut() {
    this.authService.logOut();
    this.router.navigateByUrl('/');
    this.toastr.info("Oturum Kapatıldı","Bilgi");
  }
}
