import { HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Category } from 'src/app/models/category';
import { Project } from 'src/app/models/project';
import { VirtualTour } from 'src/app/models/virtualTour';
import { AuthService } from 'src/app/services/auth.service';
import { CategoryService } from 'src/app/services/category.service';
import { GalleryService } from 'src/app/services/gallery.service';
import { ProjectService } from 'src/app/services/project.service';
import { ProjectdetailService } from 'src/app/services/projectdetail.service';
import { VirtualTourService } from 'src/app/services/virtual-tour.service';
@Component({
  selector: 'app-admin-add',
  templateUrl: './admin-add.component.html',
  styleUrls: ['./admin-add.component.css'],
})
export class AdminAddComponent implements OnInit {
  isMainCategory = '';
  progress: number = 0;

  projectAddForm: FormGroup;
  categoryAddForm: FormGroup;
  virtualAddForm: FormGroup;
  galleryAddForm: FormGroup;
  projectDetailAddForm: FormGroup;
  selectedFiles?: FileList;
  selectedFilesVirtualTour?: FileList;
  public htmldata: string = '';
  public htmldta: string = '';
  constructor(
    private formBuilder: FormBuilder,
    public projectService: ProjectService,
    public categoryService: CategoryService,
    public galleryService: GalleryService,
    private projectDetailService: ProjectdetailService,
    public virtualTourService: VirtualTourService,
    private toastr: ToastrService,
    private authservice: AuthService,
    private router:Router
  ) {

  }

  imgBtnText: string = 'Proje Kapak Resmi Seçmek İçin Tıklayınız';
  imgBtnTextVirtual: string =
    'Sanal Tur Kapak Fotoğrafını Seçmek İçin Tıklayınız';
  imgBtnTextVirtuals: string = 'Sanal Tur Yüklemek İçin Tıklayın';

  ngOnInit(): void {
    this.createProjectAddForm();
    this.createCategoryAddForm();
    //this.createGalleryAddForm();
    this.getProjects();
    this.getCategories();
    this.getCategoriesBase();
    this.createVirtualTour();
    this.createProjectDetailAddForm();
  }

  getProjects() {
    this.projectService.getProjectList();
  }
  getCategories() {
    this.categoryService.getCategoriesFilterAll();
  }
  getCategoriesBase() {
    this.categoryService.getCategoriesBaseFilterAll();
  }
  createProjectAddForm() {
    this.projectAddForm = this.formBuilder.group({
      projectName: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(50)]),
      ],

      projectDescription: ['', Validators.maxLength(300)],
      projectPicture: ['', Validators.required],
      isVitrin: [''],
      order: ['', Validators.compose([Validators.required, Validators.min(1)])],
    });
  }
  createCategoryAddForm() {
    this.categoryAddForm = this.formBuilder.group({
      Name: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(50)]),
      ],
      Pid: [''],
    });
  }
  createGalleryAddForm() {
    this.galleryAddForm = this.formBuilder.group({
      projectId: ['', Validators.required],
      categoryId: ['', Validators.required],
      categoryBase: ['', Validators.required],
      galleryPicture: ['', Validators.required],
      isOnlyAdmin: [''],
    });
  }
  createProjectDetailAddForm() {
    this.projectDetailAddForm = this.formBuilder.group({
      projectId: ['', Validators.required],
      content: ['', Validators.required],
    });
  }
  createVirtualTour() {
    this.virtualAddForm = this.formBuilder.group({
      name: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(50)]),
      ],
      description: ['', Validators.maxLength(300)],
      CoverPhoto: ['', Validators.required],
      projectId: ['', Validators.required],
      VirtualTourFolders: ['', Validators.required],
    });
  }

  selectFiles(event: any): void {
    this.selectedFiles = event.target.files;
    var fileName = this.selectedFiles[0].name;
    var idxDot = fileName.lastIndexOf('.') + 1;
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    if (extFile == 'jpg' || extFile == 'jpeg' || extFile == 'png'|| extFile == 'jfif') {
      if (this.selectedFiles) {
        this.imgBtnText = this.selectedFiles[0].name;

        this.toastr.warning('Dosya Seçildi', 'Uyarı');
      } else {
        this.toastr.error('Dosya Seçilmedi', 'Uyarı');
        this.imgBtnText = 'Lütfen Bir Dosya Seçin';
      }
    } else {
      alert('Sadece jpg/jpeg/png resim formatlarını desteklemektedir!');
    }
  }
  uploadFiles(): void {
    if (this.projectAddForm.valid) {
      if (this.selectedFiles) {
        for (let i = 0; i < this.selectedFiles.length; i++) {
          this.Addproject(this.selectedFiles[i]);
          this.toastr.warning(i + 1 + '. Görsel hafızaya alındı.', 'Uyarı');
        }
        this.toastr.success('Hafızaya Alma Başarılı', 'Başarılı');
      }
    } else {
      this.toastr.error('Lütfen * alanları doldurun.', 'Doğrulama Hatası');
    }
  }
  Addproject(file: File) {
    if (this.projectAddForm.valid) {
      let projectModel = Object.assign({}, this.projectAddForm.value);
      if (projectModel.isVitrin == '') {
        projectModel.isVitrin = false;
      }
      console.log(projectModel);
      this.projectService.addProject(projectModel, file).subscribe(
        (response) => {
          this.toastr.success('Proje Oluşturuldu. ' + response, 'Başarılı');
          this.projectService.getProjectList();
          this.projectService.getProjectListVitrin();

        },
        (errorResponse) => {
          if (errorResponse.status == 401) {
            this.toastr.error('İşlem Yetkiniz Yok!', 'Hata');
          }
          if(errorResponse.status == 500){
            localStorage.clear();
            this.router.navigateByUrl('/login');
          }
          this.toastr.error(errorResponse.message, errorResponse.name);
          this.toastr.error(errorResponse.error, errorResponse.name);
          this.toastr.error('Ekleme işlemi başarısız', 'Başarısız');
        }
      );
    } else {
      this.toastr.error('Lütfen * alanları doldurun.', 'Doğrulama Hatası');
    }
  }

  addCategory() {
    if (this.categoryAddForm.valid) {
      if (this.isMainCategory) {
        let categoryModel = Object.assign({}, this.categoryAddForm.value);
        categoryModel.Pid = null;
        this.categoryService.addCategory(categoryModel).subscribe(
          (Response) => {
            this.toastr.success(
              categoryModel.Name +
                ' Adında ana kategori olarak eklendi ' +
                Response,
              'Başarılı'
            );
            this.categoryService.getCategoryList();
          },
          (errorResponse) => {
            if (errorResponse.status == 401) {
              this.toastr.error('İşlem Yetkiniz Yok!', 'Hata');
            }
            if(errorResponse.status == 500){
              localStorage.clear();
              this.router.navigateByUrl('/login');
            }
            this.toastr.error(errorResponse.message, errorResponse.name);
            this.toastr.error(errorResponse.error, errorResponse.name);
            this.toastr.error('Ekleme işlemi başarısız', 'Başarısız');
          }
        );
      } else {
        let categoryModel = Object.assign({}, this.categoryAddForm.value);
        categoryModel.Pid = '0';
        this.categoryService.addCategory(categoryModel).subscribe(
          (Response) => {
            this.toastr.success(
              categoryModel.Name + ' Adında kategori eklendi. ' + Response,
              'Başarılı'
            );
          },
          (errorResponse) => {
            this.toastr.error(errorResponse.error, 'Hata');
          }
        );
      }
      this.getCategories();
      this.getCategoriesBase();
    } else {
      this.toastr.error('Lütfen * alanları boş bırakmayın', 'Doğrulama Hatası');
    }
  }

  uploadGalleryFiles(): void {
    if (this.galleryAddForm.valid) {
      if (this.selectedFiles) {
        this.addGallery(this.selectedFiles);
      } else {
        this.toastr.warning('Resimler Seçilmedi!', 'Uyarı');
      }
    } else {
      this.toastr.error('Lütfen * alanları boş bırakmayın', 'Doğrulama Hatası');
    }
  }
  addGallery(files: any) {
    if (this.galleryAddForm.valid) {
      let galleryModel = Object.assign({}, this.galleryAddForm.value);
      if (galleryModel.isOnlyAdmin == '') {
        galleryModel.isOnlyAdmin = 'false';
      }
      console.log(galleryModel);
      this.galleryService.addGallery(galleryModel, files).subscribe(
        (response) => {
          this.toastr.success(
            'Galeriye görseller yüklendi. ' + response,
            'Başarılı'
          );
          this.galleryService.getGalleriesDtoAll();
          this.galleryService.getGalleryList();
        },
        (errorResponse) => {
          if (errorResponse.status == 401) {
            this.toastr.error('İşlem Yetkiniz Yok!', 'Hata');
          }
          if(errorResponse.status == 500){
            localStorage.clear();
            this.router.navigateByUrl('/login');
          }
          this.toastr.error(errorResponse.message, errorResponse.name);
          this.toastr.error(errorResponse.error, errorResponse.name);
          this.toastr.error('Ekleme işlemi başarısız', 'Başarısız');
        }
      );
    } else {
      this.toastr.error('Lütfen * alanları boş bırakmayın', 'Doğrulama Hatası');
    }
  }
  addProjectDetailPage() {
    if (this.projectDetailAddForm.valid) {
      const values = Object.assign({}, this.projectDetailAddForm.value);

      this.projectDetailService.addProjectDetailPage(values).subscribe(
        (response) => {
          this.toastr.success('içerik yüklendi. ' + response, 'Başarılı');
        },
        (errorResponse) => {
          if (errorResponse.status == 401) {
            this.toastr.error('İşlem Yetkiniz Yok!', 'Hata');
          }
          if(errorResponse.status == 500){
            localStorage.clear();
            this.router.navigateByUrl('/login');
          }
          this.toastr.error(errorResponse.message, errorResponse.error);
          this.toastr.error('Ekleme işlemi başarısız', 'Başarısız');
        }
      );
    } else {
      this.toastr.error('Lütfen * alanları boş bırakmayın', 'Doğrulama Hatası');
    }
  }

  coverphoto: File;
  selectedVirtualFiles?: FileList;
  selectVirtoulTourCoverPhoto(event: any): void {
    this.selectedVirtualFiles = event.target.files;
    for (let i = 0; i < this.selectedVirtualFiles.length; i++) {
      this.coverphoto = this.selectedVirtualFiles[i];
    }
    var fileName = this.coverphoto.name;
    var idxDot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();

    if (extFile=="jpg" || extFile=="jpeg" || extFile=="png"){
      this.imgBtnTextVirtual = this.selectedVirtualFiles[0].name;
      this.toastr.warning('Görsel seçildi.', 'Uyarı');
    }else{
        alert("Sadece jpg/jpeg/png resim formatlarını desteklemektedir!");
    }

  }

  selectFilesVirtualTours(event: any): void {
    this.selectedFilesVirtualTour = event.target.files;
    if (this.selectedFilesVirtualTour) {
      this.imgBtnTextVirtuals = this.selectedFilesVirtualTour[0].name;

      this.toastr.warning('Dosya Seçildi', 'Uyarı');
    } else {
      this.toastr.error('Dosya Seçilmedi', 'Uyarı');
      this.imgBtnText = 'Lütfen Bir Dosya Seçin';
    }
  }

  uploadVirtualTourFiles(): void {
    if (this.virtualAddForm.valid) {
      if (this.selectedFilesVirtualTour) {
        this.addVirtualTour(this.selectedFilesVirtualTour);
      } else {
        this.toastr.warning('Sanal tur seçilmedi.', 'Uyarı');
      }
    } else {
      this.toastr.error('Lütfen * alanları boş bırakmayın', 'Doğrulama Hatası');
    }
  }
  addVirtualTour(files: FileList) {
    if (this.virtualAddForm.valid) {
      this.progress = 0;
      let virtualTourModel = Object.assign({}, this.virtualAddForm.value);

      this.virtualTourService
        .addVirtualTour(
          virtualTourModel,
          files,
          this.coverphoto,
          this.authservice.userId
        )
        .subscribe(
          (response) => {
            if (response.type === HttpEventType.UploadProgress) {
              this.progress = Math.round(
                (100 * response.loaded) / response.total
              );

              if (this.progress == 100) {
                this.toastr.success('Sanal tur yüklendi.', 'Başarılı');
                this.virtualTourService.getVirtualTourListDto();
                this.virtualTourService.getVirtualTourListAll();
                //window.location.reload();
              }

              this.toastr.warning('Sanal tur yükleniyor.', 'Uyarı');
            }
          },
          (errorResponse) => {
            if (errorResponse.status == 401) {
              this.toastr.error('İşlem Yetkiniz Yok!', 'Hata');
            }
            if(errorResponse.status == 500){
              localStorage.clear();
              this.router.navigateByUrl('/login');
            }
            this.toastr.error(errorResponse.message, 'Hata');
            this.toastr.error('Ekleme işlemi başarısız', 'Başarısız');
          }
        );
    } else {
      this.toastr.error('Lütfen * alanları boş bırakmayın', 'Doğrulama Hatası');
    }
  }
}
