<app-navi></app-navi>
<div *ngIf="adminControl()">
  <div class="container margin_top_70">
    <mat-tab-group mat-align-tabs="center">
        <mat-tab label="Ekle">
           <app-admin-add></app-admin-add>
        </mat-tab>
        <mat-tab label="Listele ve Sil">
          <app-admin-list-and-delete></app-admin-list-and-delete>

         </mat-tab>
        <mat-tab label="Güncelle"><app-admin-update></app-admin-update></mat-tab>
        <mat-tab label="Genel Ayarlar"><app-general-settings></app-general-settings></mat-tab>
        
      </mat-tab-group>
</div>
</div>

<app-footer></app-footer>