
<div class="container mb-5 mt-2">
    <div class="row">
        <div class="col col-lg-3">
            <div class="card">
              <div class="card-header">Şifre Değiştir</div>
              <div class="card-body">
                <blockquote class="blockquote mb-0">
                  <form class="row" [formGroup]="changePasswordFormGroup">
                    
                    <label for="newPassword">Yeni Şifre</label>
                    <div class="input-group">
                      <input
                        type="password"
                        name="password"
                        id="newPassword"
                        formControlName="password"
                        class="form-control"
                      />
                      <div class="input-group-append" onclick="newPasswordShowHide()">
                        <span class="input-group-text">
                          <i class="fa fa-eye" id="icon"></i>
                        </span>
                      </div>
                    </div>
                    <label for="repeatPassword">Yeni Şifre Tekrarı</label>
                    <div class="input-group">
                      <input
                        type="password"
                        name="repeatPassword"
                        id="repeatPassword"
                        formControlName="repeatPassword"
                        class="form-control"
                      />
                      <div class="input-group-append" onclick="repeatPasswordShowHide()">
                        <span class="input-group-text">
                          <i class="fa fa-eye" id="icon"></i>
                        </span>
                      </div>
                    </div>
        
                    <div class="col-auto">
                      <button (click)="changeUserPassword()" class="btn btn-primary mb-3 mt-3">
                        Şifremi Değiştir
                      </button>
                    </div>
                  </form>
                  <footer class="blockquote-footer">
                    Şifre değişikliği için bu alanı kullanın.
                  </footer>
                </blockquote>
              </div>
            </div>
          </div>
        
         
    </div>
  


</div>
