
  <body class="text-center">
    <main class="form-signin">
      <form [formGroup]="loginForm">
        
        <h1 class="h3 mb-3 fw-normal">KGDESİGN Mimarlık Yönetici Girişi</h1>
  
        <div class="form-floating">
          <label for="floatingInput">Email Adresi</label>
          <input
            type="email"
            class="form-control"
            id="floatingInput"
            placeholder="isim@ornek.com"
            formControlName="email"
          />
          
        </div>
        <div class="form-floating">
          <label for="floatingPassword">Şifre</label>
          <input
            type="password"
            class="form-control"
            id="floatingPassword"
            placeholder="Şifre"
            formControlName="password"
          />
          
        </div>
  
        <!-- <div class="checkbox mb-3">
          <label>
            <input type="checkbox" value="remember-me" /> Beni Hatırla
          </label>
        </div> -->
        
        <button class="w-100 btn btn-lg btn-primary" (click)="login()">
          Giriş Yap
        </button>
        <div class="mt-4">
          <a routerLink="/forgotpassword">Şifremi Unuttum</a>
        </div>
        
        <p class="mt-5 mb-3 text-muted">&copy; 2017–2021 | Tasarımda ki şıklık </p>
      </form>
    </main>
  </body>


