import { HttpEventType } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { Category } from 'src/app/models/category';
import { Gallery } from 'src/app/models/gallery';
import { Project } from 'src/app/models/project';
import { VirtualTour } from 'src/app/models/virtualTour';
import { AuthService } from 'src/app/services/auth.service';
import { CategoryService } from 'src/app/services/category.service';
import { GalleryService } from 'src/app/services/gallery.service';
import { ProjectService } from 'src/app/services/project.service';
import { ProjectdetailService } from 'src/app/services/projectdetail.service';
import { VirtualTourService } from 'src/app/services/virtual-tour.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-update',
  templateUrl: './admin-update.component.html',
  styleUrls: ['./admin-update.component.css'],
})
export class AdminUpdateComponent implements OnInit {
  project: Project;
  category: Category;
  gallery: Gallery;
  virtualTour: VirtualTour;

  categoriesBase: Category[];
  progress: number = 0;
  isUpdateButtonProjectDetail: string = 'disabled';

  imgBtnText: string = 'Proje Kapak Resmi Seçmek İçin Tıklayınız';
  imgBtnTextVirtual: string =
    'Sanal Tur Kapak Fotoğrafını Seçmek İçin Tıklayınız';

  projectUpdateForm: FormGroup;
  categoryUpdateForm: FormGroup;
  virtualUpdateForm: FormGroup;
  galleryUpdateForm: FormGroup;
  projectDetailUpdateForm: FormGroup;

  //project items
  input_projectName = '';
  input_projectDescription = '';
  input_projectPicture = ' ';
  input_projectPictureimg = '';
  input_isVitrin = '';
  input_order: Number;

  //category Items
  input_categoryName = '';

  //gallery Items
  input_galleryName = '';
  input_galleryProjectId = 0;
  input_galleryCategoryId = 0;
  input_galleryCategoryBaseId = 0;
  input_galleryPictureimg = '';
  input_galleryPicture = '';

  //virtualTour Items
  input_virtualTourName = '';
  input_virtualTourDescription = '';
  input_virtualTourPictureimg = '';
  input_virtualTourPicture = '';
  input_virtualTourProjectId = 0;

  public htmldata: string = ' ';
  selectedFiles?: FileList;

  constructor(
    public projectService: ProjectService,
    public categoryService: CategoryService,
    public galleryService: GalleryService,
    private projectDetailService: ProjectdetailService,
    public virtualTourService: VirtualTourService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private authservice:AuthService,
    private router:Router
  ) {}

  ngOnInit(): void {

    this.createProjectUpdateForm(),
      this.createCategoryUpdateForm(),
      this.createGalleryUpdateForm();
    this.createVirtualTourUpdateForm();
    this.createProjectDetailAddForm();
  }

  createProjectUpdateForm() {
    this.projectUpdateForm = this.formBuilder.group({
      projectName: [
        '',
        Validators.compose([Validators.maxLength(50), Validators.required]),
      ],
      projectId: ['', Validators.required],
      projectDescription: [''],
      projectPicture: [''],
      isVitrin: [''],
      order: ['', Validators.compose([Validators.required, Validators.min(1)])],
    });
  }
  createCategoryUpdateForm() {
    this.categoryUpdateForm = this.formBuilder.group({
      categoryId: ['', Validators.required],
      name: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(50)]),
      ],
      categoryPid: [''],
    });
  }
  createGalleryUpdateForm() {
    this.galleryUpdateForm = this.formBuilder.group({
      galleryId: ['', Validators.required],
      projectId: ['', Validators.required],
      categoryId: ['', Validators.required],
      categoryBase: ['', Validators.required],
      picture: [''],
      name: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(100)]),
      ],
    });
  }
  createVirtualTourUpdateForm() {
    this.virtualUpdateForm = this.formBuilder.group({
      virtualTourId: ['', Validators.required],
      name: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(50)]),
      ],
      description: ['', Validators.maxLength(300)],
      pictureThumbnail: [''],
      projectId: ['', Validators.required],
      dataUrl: [''],
    });
  }

  createProjectDetailAddForm() {
    this.projectDetailUpdateForm = this.formBuilder.group({
      projectId: ['', Validators.required],
      content: [''],
    });
  }

  selectedProject(id: number) {
    this.input_isVitrin = '';
    this.projectService.getProjectById(id).subscribe((response) => {
      (this.project = response),
        (this.input_projectName = this.project.projectName),
        (this.input_projectDescription = this.project.projectDescription),
        (this.input_projectPictureimg =
          environment.getUrl + this.project.projectThumbnail),
        (this.input_isVitrin = String(this.project.isVitrin)),
        (this.input_order = Number(this.project.order)),
        (this.input_projectPicture = this.project.projectThumbnail);
    });
  }

  selectFiles(event: any): void {
    this.selectedFiles = event.target.files;
    var fileName = this.selectedFiles[0].name;
    var idxDot = fileName.lastIndexOf('.') + 1;
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    if (extFile == 'jpg' || extFile == 'jpeg' || extFile == 'png'|| extFile == 'jfif') {
      if (this.selectedFiles) {
        this.imgBtnText = this.selectedFiles[0].name;

        this.toastr.warning('Dosya Seçildi', 'Uyarı');
      } else {
        this.toastr.error('Dosya Seçilmedi', 'Uyarı');
        this.imgBtnText = 'Lütfen Bir Dosya Seçin';
      }
    } else {
      alert('Sadece jpg/jpeg/png resim formatlarını desteklemektedir!');
    }
  }

  updateProject() {
    if (this.projectUpdateForm.valid) {
      let projectModel = Object.assign({}, this.projectUpdateForm.value);
      if (projectModel.projectPicture != '') {
        if (projectModel.isVitrin == '') {
          projectModel.isVitrin = false;
        }

        if (this.selectedFiles) {
          for (let index = 0; index < this.selectedFiles.length; index++) {
            this.projectService
              .updateProject(projectModel, this.selectedFiles[index])
              .subscribe(
                (response) => {
                  this.toastr.success('Güncelleme Başarılı', 'Başarılı'),
                    this.projectService.getProjectList();
                  this.projectService.getProjectListVitrin();
                  this.input_isVitrin = '';
                  window.history.forward();
                },
                (errorResponse) => {
                  if (errorResponse.status == 401) {
                    this.toastr.error('İşlem Yetkiniz Yok!', 'Hata');
                  }
                  if(errorResponse.status == 500){
                    localStorage.clear();
                    this.router.navigateByUrl('/login');
                  }
                  this.toastr.error(errorResponse.message, errorResponse.name);
                  this.toastr.error(errorResponse.error, errorResponse.name);
                  this.toastr.error('Ekleme işlemi başarısız', 'Başarısız');
                }
              );
          }
        }
      } else {
        projectModel.projectPicture = this.input_projectPicture;
        if (projectModel.isVitrin == '') {
          projectModel.isVitrin = false;
        }

        this.projectService.updateProject(projectModel, null).subscribe(
          (response) => {
            this.toastr.success('Güncelleme Başarılı', 'Başarılı'),
              this.projectService.getProjectList();
          },
          (errorResponse) => {
            this.toastr.error(errorResponse.message, errorResponse.name);
          }
        );
      }
    } else {
      this.toastr.error('Lütfen * alanları doldurun.', 'Doğrulama Hatası');
    }
  }
  selectedCategory(id: number) {
    this.categoryService.getCategoryById(id).subscribe(
      (response) => {
        (this.category = response),
          (this.input_categoryName = this.category.name);
      },
      (errorResponse) => {
        if (errorResponse.status == 401) {
          this.toastr.error('İşlem Yetkiniz Yok!', 'Hata');
        }
        if(errorResponse.status == 500){
          localStorage.clear();
          this.router.navigateByUrl('/login');
        }
        this.toastr.error(errorResponse.message, errorResponse.name);
        this.toastr.error('Ekleme işlemi başarısız', 'Başarısız');
      }
    );
  }
  updateCategory() {
    if (this.categoryUpdateForm.valid) {
      let categoryModel = Object.assign({}, this.categoryUpdateForm.value);
      if (categoryModel.categoryPid == '') {
        categoryModel.categoryPid = '0';
      } else {
        categoryModel.categoryPid = null;
      }
      this.categoryService.updateCategory(categoryModel).subscribe(
        (response) => {
          this.toastr.success('Güncelleme Başarılı', 'Başarılı'),
            this.categoryService.getCategoriesBaseFilterAll(),
            this.categoryService.getCategoriesFilterAll(),
            this.categoryService.getCategoryList();
        },
        (errorResponse) => {
          if (errorResponse.status == 401) {
            this.toastr.error('İşlem Yetkiniz Yok!', 'Hata');
          }
          if(errorResponse.status == 500){
            localStorage.clear();
            this.router.navigateByUrl('/login');
          }
          this.toastr.error(errorResponse.message, errorResponse.name);
          this.toastr.error('Ekleme işlemi başarısız', 'Başarısız');
        }
      );
    } else {
      this.toastr.error('Lütfen * alanları doldurun.', 'Doğrulama Hatası');
    }
  }
  selectedGallery(id: number) {
    this.galleryService.getGalleryById(id).subscribe((response) => {
      (this.gallery = response),
        (this.input_galleryName = this.gallery.name),
        (this.input_galleryCategoryId = this.gallery.categoryId),
        (this.input_galleryCategoryBaseId = this.gallery.categoryBase),
        (this.input_galleryPictureimg =
          environment.getUrl + this.gallery.picture),
        (this.input_galleryPicture = this.gallery.picture),
        (this.input_galleryProjectId = this.gallery.projectId);
    });
  }
  updateGallery() {
    if (this.galleryUpdateForm.valid) {
      let galleryModel = Object.assign({}, this.galleryUpdateForm.value);
      console.log(galleryModel);
      if (galleryModel.picture != '') {
        console.log(galleryModel);
        if (this.selectedFiles) {
          for (let index = 0; index < this.selectedFiles.length; index++) {
            this.galleryService
              .updateGallery(galleryModel, this.selectedFiles[index])
              .subscribe(
                (response) => {
                  this.toastr.success('Güncelleme Başarılı', 'Başarılı'),
                    this.galleryService.getGalleryList();
                  this.galleryService.getGalleriesDtoAll();
                  window.history.forward();
                },
                (errorResponse) => {
                  if (errorResponse.status == 401) {
                    this.toastr.error('İşlem Yetkiniz Yok!', 'Hata');
                  }
                  if(errorResponse.status == 500){
                    localStorage.clear();
                    this.router.navigateByUrl('/login');
                  }
                  this.toastr.error(errorResponse.message, errorResponse.name);
                  this.toastr.error(errorResponse.error, errorResponse.name);
                  this.toastr.error('Ekleme işlemi başarısız', 'Başarısız');
                }
              );
          }
        }
      } else {
        galleryModel.picture = this.input_galleryPicture;
        console.log(galleryModel);
        this.galleryService.updateGallery(galleryModel, null).subscribe(
          (response) => {
            this.toastr.success('Güncelleme Başarılı', 'Başarılı'),
              this.galleryService.getGalleryList();
          },
          (errorResponse) => {
            this.toastr.error(errorResponse.message, errorResponse.name);
          }
        );
      }
    } else {
      this.toastr.error('Lütfen * alanları doldurun.', 'Doğrulama Hatası');
    }
  }
  selectedVirtualTour(virtualtour: VirtualTour) {
    this.input_virtualTourDescription = virtualtour.description;
    this.input_virtualTourName = virtualtour.name;
    this.input_virtualTourPicture =
      environment.getUrl + virtualtour.pictureThumbnail;
    this.input_virtualTourPictureimg =
      environment.getUrl + virtualtour.pictureThumbnail;
    this.input_virtualTourProjectId = virtualtour.projectId;
    this.selectedFiles = null;
    this.coverphoto = null;
  }

  coverphoto: File;
  selectedVirtualFiles?: FileList;
  selectVirtoulTourCoverPhoto(event: any): void {
    this.selectedVirtualFiles = event.target.files;
    for (let i = 0; i < this.selectedVirtualFiles.length; i++) {
      this.coverphoto = this.selectedVirtualFiles[i];
    }
    var fileName = this.coverphoto.name;
    var idxDot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();

    if (extFile=="jpg" || extFile=="jpeg" || extFile=="png"|| extFile=="jfif"){
      this.imgBtnTextVirtual = this.selectedVirtualFiles[0].name;
      this.toastr.warning('Görsel seçildi.', 'Uyarı');
    }else{
        alert("Sadece jpg/jpeg/png resim formatlarını desteklemektedir!");
    }

  }

  uploadVirtualTourFiles(): void {
    if (this.virtualUpdateForm.valid) {
      this.addVirtualTour(this.selectedFiles);
    } else {
      this.toastr.error('Lütfen * alanları boş bırakmayın', 'Doğrulama Hatası');
    }
  }
  addVirtualTour(files: FileList) {
    if (this.virtualUpdateForm.valid) {
      this.progress = 0;
      let virtualTourModel = Object.assign({}, this.virtualUpdateForm.value);

      this.virtualTourService
        .updateVirtualTour(virtualTourModel, this.coverphoto, files)
        .subscribe(
          (response) => {
            if (response.type === HttpEventType.UploadProgress) {
              this.progress = Math.round(
                (100 * response.loaded) / response.total
              );

              if (this.progress == 100) {
                this.toastr.success('Sanal tur yüklendi.', 'Başarılı');
                this.toastr.success('Güncelleme Başarılı.', 'Başarılı');
                this.virtualTourService.getVirtualTourListAll();


              }

              this.toastr.warning('Sanal tur yükleniyor.', 'Uyarı');
            }
          },
          (errorResponse) => {
            if (errorResponse.status == 401) {
              this.toastr.error('İşlem Yetkiniz Yok!', 'Hata');
            }
            if(errorResponse.status == 500){
              localStorage.clear();
              this.router.navigateByUrl('/login');
            }
            this.toastr.error(errorResponse.message, 'Hata');
            this.toastr.error('Güncelleme işlemi başarısız', 'Başarısız');
          }
        );
    } else {
      this.toastr.error('Lütfen * alanları boş bırakmayın', 'Doğrulama Hatası');
    }
  }

  selectedProjectDetail(projectid: number) {
    this.projectDetailService
      .getProjectDetailPagebyProjectID(projectid)
      .subscribe((response) => {
        if (response) {
          this.isUpdateButtonProjectDetail = '';
          this.htmldata = response.content;
        } else {
          this.isUpdateButtonProjectDetail = 'disabled';
          this.htmldata = '';
          this.toastr.info(
            'Projeye ait içerik bulunamadı! Lütfen içerik eklemek için ekle sayfasını kullanın.',
            'Bilgi'
          );
        }
      });
  }
  updateProjectDetail() {
    if (this.projectDetailUpdateForm.valid) {
      const values = Object.assign({}, this.projectDetailUpdateForm.value);
      this.projectDetailService.updateProjectDetailPage(values).subscribe(
        (response) => {
          this.toastr.success('içerik yüklendi. ' + response, 'Başarılı');
        },
        (errorResponse) => {
          if (errorResponse.status == 401) {
            this.toastr.error('İşlem Yetkiniz Yok!', 'Hata');
          }
          if(errorResponse.status == 500){
            localStorage.clear();
            this.router.navigateByUrl('/login');
          }
          this.toastr.error(errorResponse.message, errorResponse.name);
          this.toastr.error(errorResponse.error, errorResponse.name);
          this.toastr.error('Ekleme işlemi başarısız', 'Başarısız');
        }
      );
    } else {
      this.toastr.error('Lütfen * alanları boş bırakmayın', 'Doğrulama Hatası');
    }
  }
}
