<div style="margin: auto">
  <h1 mat-dialog-title class="text-danger">{{ data.title }}</h1>
  <div mat-dialog-content style="overflow: hidden">
    <textarea
      cols="70"
      rows="5"
      style="
        max-width: 100%;
        font-size: 1.2rem;
        line-height: 1.5rem;
        letter-spacing: 1px;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      "
      >{{ data.text }}</textarea
    >
  </div>

  <div>
    <button class="btn btn-danger mr-2" (click)="onNoClick(true)">
      {{ data.buttonOkText }}
    </button>

    <button class="btn btn-warning" (click)="onNoClick()" cdkFocusInitial>
      {{ data.buttonCancelText }}
    </button>
  </div>
</div>
