
<table class="table table-striped mt-3">
    <thead>
      <tr>
       <th scope="col">#</th>
       <th scope="col">Adı</th>
       <th scope="col">Açıklama</th>
       <th scope="col">Proje Adı</th>
       <th scope="col">Adres</th>
       <th scope="col">Görsel</th>
       <th scope="col">Sanal Tur</th>
       <th scope="col">İşlem</th>

      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let x of virtualTourService.virtualToursDto">
        <th scope="row">{{x.virtualTourId}}</th>
        <td>{{x.name}}</td>
        <td style="max-width:200px; word-wrap: break-word;"><div [innerHTML]="x.description | htmldecoder"></div></td>
        <td>{{x.projectName}}</td>
        <td>{{x.dataUrl}}</td>
        <td><img src="{{path}}{{x.pictureThumbnail}}" class="img-thumbnail" style="max-width: 150px; max-height: 150px;"/></td>
        <td><a routerLink="/sanalturlar/sanaltur/{{x.dataUrl}}"  target="_blank" class="btn btn-info btn-sm">Görüntüle</a></td>
        <td>
          <button type="button"  class="btn btn-danger btn-sm" (click)="deleteVirtualTour(x.virtualTourId)">Sil</button>

        </td>

      </tr>

    </tbody>
  </table>


