<div class="container">
  <div class="row form-gap">
    <div class="col-md-4 col-md-offset-4 container">
      <div class="panel panel-default">
        <div class="panel-body">
          <div class="text-center">
            <h3><i class="fa fa-lock fa-4x"></i></h3>
            <h2 class="text-center">Şifreni mi unuttun?</h2>
            <p>Şifre sıfırlamak için E-Posta adresinizi yazınız.</p>
            <div class="panel-body">
              <form
                id="register-form"
                role="form"
                [formGroup]="emailFormGroup"
                class="form"
              >
                <div class="row">
                  <div class="form-group col-lg-12">
                    <div class="input-group">
                      <span class="input-group-addon"
                        ><i class="glyphicon glyphicon-envelope color-blue"></i
                      ></span>
                      <input
                        id="email"
                        name="email"
                        formControlName="email"
                        placeholder="Kayıtlı E-Posta Adresi"
                        class="form-control"
                        type="email"
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-lg-12">
                    <input
                      name="recover-submit"
                      class="btn btn-lg btn-primary btn-block"
                      value="Sıfırlama Kodu Gönder"
                      (click)="sendEmail()"
                    />
                  </div>

                  <input
                    type="hidden"
                    class="hide"
                    name="token"
                    id="token"
                    value=""
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
