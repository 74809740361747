import { Component, OnInit } from '@angular/core';
import { Project } from 'src/app/models/project';
import { ProjectService } from 'src/app/services/project.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css'],
})
export class SliderComponent implements OnInit {
 
  currentProject: Project;
  path=environment.getUrl;
  constructor(public projectService: ProjectService) {}

  ngOnInit(): void {
    this.getprojects();
  }
  getprojects() {
    this.projectService.getProjectListVitrin();
    
  }

  getSlideClass(index:Number){
    if(index == 0){
      return "carousel-item active";
    } else {
      return "carousel-item";
    }
  }
  getSlideNumber(index:Number){
    return index.toString();
  }

  setCurrentProject(project: Project) {
    this.currentProject = project;
  }

  getCurrentProjectclass(project: Project) {
    if (project == this.currentProject) {
      return 'active';
    } else {
      return '';
    }
  }







}
