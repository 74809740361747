import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent implements OnInit {
  emailFormGroup: FormGroup;
  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.createEmailForm();
    this.activatedRoute.params.subscribe((params) => {
      if (params['email'] && params['verifiCode']) {
        this.resetPassword(params['email'], params['verifiCode']);
        this.router.navigateByUrl('/login');
      }
    });
  }

  randomCode() {
    var result = [];
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < 4; i++) {
      result.push(
        characters.charAt(Math.floor(Math.random() * charactersLength))
      );
    }
    console.log(result.join(''));
  }
  createEmailForm() {
    this.emailFormGroup = this.formBuilder.group({
      email: ['', Validators.email],
    });
  }
  sendEmail() {
    let sendMailModel = Object.assign({}, this.emailFormGroup.value);
    console.log(sendMailModel);
    this.authService.sendEmail(sendMailModel).subscribe(
      (response) => {
        this.toastr.success(response.message, 'Başarılı');
        this.toastr.info("Mail Kutunuzu Kontrol Ediniz!", 'Bilgi');
      },
      (errorResponse) => {
        this.toastr.error(errorResponse.error.message, 'Hata');
      }
    );
  }
  resetPassword(email: string, verifiCode: string) {
    const obj = { email: email, verifiCode: verifiCode };
    const resetModel = Object.assign({}, obj);
    this.authService.forgotPassword(resetModel).subscribe(
      (response) => {
        this.toastr.success(response.message, 'Başarılı');
      },
      (errorResponse) => {
        this.toastr.error(errorResponse.error.message, 'Hata');
      }
    );
  }
}
