import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ListResponseModel } from '../models/listResponseModel';
import { VirtualTour } from '../models/virtualTour';
import { ResponseModel } from '../models/responseModel';
import { environment } from 'src/environments/environment';
import { VirtualTourDto } from '../models/virtualtourDto';
import { AuthService } from './auth.service';
@Injectable({
  providedIn: 'root',
})
export class VirtualTourService {
  public virtualToursDto: VirtualTourDto[];
  public virtualTours: VirtualTour[];
  constructor(private httpClient: HttpClient,private authService:AuthService) {
   this.getVirtualTourListDto();
   this.getVirtualTourListAll();
  }

  getVirtualTourById(id: number): Observable<VirtualTour> {
    return this.httpClient.get<VirtualTour>(
      environment.getApiUrl + '/virtualTours/getbyid?virtualTourId=' + id
    );
  }
  getVirtualTourByProjectId(projectId: number): Observable<VirtualTour[]> {
    return this.httpClient.get<VirtualTour[]>(
      environment.getApiUrl +
        '/virtualTours/getvirtualtoursbyprojectid?projectId=' +
        projectId
    );
  }
  getVirtualToursDTOByProjectId(projectId: number): Observable<VirtualTourDto[]> {
    return this.httpClient.get<VirtualTourDto[]>(
      environment.getApiUrl +
        '/virtualTours/getvirtualtoursdtobyprojectid?projectId=' +
        projectId
    );
  }

  addVirtualTour(
    virtualTour: VirtualTour,
    files: FileList,
    coverphoto: File,userId:number
  ): Observable<HttpEvent<any>> {
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append('Files', files[i]);
    }
    formData.append('projectId', virtualTour.projectId.toString());
    formData.append('name', virtualTour.name);
    formData.append('description', virtualTour.description);
    formData.append('CoverPhoto', coverphoto);
    formData.append('UserId', String(userId));

    return this.httpClient.post(
      environment.getApiUrl + '/virtualTours/',
      formData,
      { responseType: 'text', reportProgress: true, observe: 'events' }
    );
  }

  getVirtualTourListDto() {
    this.httpClient
      .get<VirtualTourDto[]>(environment.getApiUrl + '/virtualTours/getalldto').subscribe(response=>{this.virtualToursDto=response});

  }
  getVirtualTourListAll() {
    this.httpClient
      .get<VirtualTour[]>(environment.getApiUrl + '/virtualTours/getall').subscribe(response=>{this.virtualTours=response});

  }
  getVirtualTourListDtobyUser(userId:number,projectId:number): Observable<VirtualTourDto[]> {
    return this.httpClient.get<VirtualTourDto[]>(
      environment.getApiUrl + '/virtualTours/getdto?userId='+userId+'&&projectId='+projectId
    );
  }
  updateVirtualTour(virtualTour: VirtualTour,CoverPhoto:File,files:FileList): Observable<HttpEvent<any>>{
    const formData:FormData=new FormData();
    formData.append('virtualTourId',virtualTour.virtualTourId.toString());
    formData.append('name',virtualTour.name);
    formData.append('pictureThumbnail',virtualTour.pictureThumbnail);
    formData.append('description',virtualTour.description);
    formData.append('projectId',virtualTour.projectId.toString());
    formData.append('Image',CoverPhoto);
    formData.append('dataUrl',virtualTour.dataUrl);
    formData.append('UserId', String(this.authService.userId));
    if(files){
      for (let i = 0; i < files.length; i++) {
        formData.append('Files', files[i]);
      }
    }

    return this.httpClient.put(
      environment.getApiUrl + '/virtualTours/',
      formData,
      { responseType: 'text', reportProgress: true, observe: 'events'}
    );
  }

  deleteVirtualTour(id: number) {
    return this.httpClient.request(
      'delete',
      environment.getApiUrl + '/virtualTours/',
      { body: { virtualTourId: id } }
    );
  }
}
