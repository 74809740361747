import { Pipe, PipeTransform } from '@angular/core';
import { Gallery } from '../models/gallery';

@Pipe({
  name: 'galeryFilter'
})
export class GaleryFilterPipe implements PipeTransform {

  transform(value: Gallery[], categoryId:string, categoryBasee?:string): Gallery[] {
    let cb:string=categoryBasee?categoryBasee:'';
    return categoryId ? value.filter((c:Gallery)=>c.categoryId.toString().indexOf(categoryId)!==-1 || c.categoryBase.toString().indexOf(cb)!==-1):value;
  }

}
