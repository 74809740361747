import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.css']
})
export class IframeComponent implements OnInit {

  constructor(public sanitizer: DomSanitizer,private activatedRoute: ActivatedRoute,
    private authService:AuthService) { }
  url: string = "";
  urlSafe: SafeResourceUrl;
  

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      if (params['vtname']) {
        this.url=environment.getUrl+params['vtname'];   
        this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
      }
  });
    
  }

}
