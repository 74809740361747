// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
/**
 *
 */



export const environment = {
  production: false,
  getApiUrl: "https://api.kadirguzinmimarlik.com/api",
  getUrl:"https://api.kadirguzinmimarlik.com/",
  getsiteUrl:location.origin+"/",
  getDropDownSetting: {
    singleSelection: false,
    idField: 'id',
    textField: 'label',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  },
  getDatatableSettings:  {
    pagingType: 'full_numbers',
    pageLength: 2
  }

};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
