<app-navi></app-navi>
<div class="about-bg-css container-fluid">
  <div class="about-bg2-css">
    <div class="container top-padding">
      <div class="row align-items-start"></div>
      <div class="row align-items-center">
        <div class="col">
          <h1 class="about-h1-css">Hakkımızda</h1>
          <p class="about-p-css">
            Mimar Kadir Onur Güzin tarafından çağdaş proje tasarımını vizyon
            edinerek 2020 yılında <em>Kadir Guzin Mimarlık</em> &nbsp; olarak yolculuğumuza
            başladık. Kuruluşumuzdan bu yana müşteri odaklı, yaratıcı ve çağın
            ihtiyaçlarını karşılayacak deneyimler üzerinde çalışmaktayız. Sizleri
            dinleyip , istekleriniz doğrultusunda en kısa zamanda en yaratıcı
            çözümlerimizi sizlerle paylaşıyoruz.
          </p>
        </div>
        <div class="col">
          <h1 class="about-h1-css">Hizmetlerimiz</h1>
          <p class="about-p-css">
            Amacımız müşterilerimize sunduğumuz tasarımların , farklı beklentileri
            karşılayacak kalitede, uygulama aşamasında ise hayallerin gerçeğe
            dönüşebildiğini gösterecek nitelikte olmasıdır. Verdiğimiz hizmetleri
            şu başlıklar altında toplayabiliriz.
          </p>
          <ul class="about-ul-css list-unstyled">
            <li>
              <i class="far fa-check-circle about-i-css"></i> Mimari Tasarım, Proje ve
              Uygulama Hizmetleri
            </li>
            <li>
              <i class="far fa-check-circle about-i-css"></i> İç Mimari Tasarım, Proje ve
              Uygulama Hizmetleri
            </li>
            <li>
              <i class="far fa-check-circle about-i-css"></i> Proje Yönetimi ve uygulama
              kontrolürlüğü
            </li>
            <li>
              <i class="far fa-check-circle about-i-css"></i> Mekanik, Elektrik, İnşaat
              Mühendisliği ve Peyzaj gibi farklı disiplinler arası proje-uygulama
              koordinasyonunun sağlanması
            </li>
          </ul>
        </div>
      </div>
      <div class="row align-items-end text-center">
        <div class="col">
          <h3>Kadir Guzin Mimarlık - <em>Tasarımın Sanatsal Hali...</em></h3>
        </div>
      </div>
    </div>
  </div>

</div>
<app-footer></app-footer>
