import { Component, OnInit } from '@angular/core';
import { Project } from 'src/app/models/project';
import { ProjectService } from 'src/app/services/project.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css'],
})
export class ProjectsComponent implements OnInit {
  
  dataLoaded = false;
  currentProject:Project;
  path=environment.getUrl;
  constructor(public projectService: ProjectService) {}

  ngOnInit(): void {
    this.getProjects();
  }
  getProjects() {
    if(this.projectService.getProjectList()){
      this.dataLoaded=true;
    }
    else{
      this.dataLoaded=false;
    }

    
  }
  setCurrentProject(project:Project){
this.currentProject=project;
  }
}
