<app-navi></app-navi>
  <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
      <li (click)="setCurrentProject(project)" data-target="#carouselExampleControls" [attr.data-slide-to]="i" [class]="getCurrentProjectclass(project)" *ngFor="let project of projectService.sliderProjects; index as i" [attr.data-index]="i"></li>
    </ol>
    <div class="carousel-inner">
      <div
        *ngFor="let image of projectService.sliderProjects; let i = index"
        [ngClass]="getSlideClass(i)"
      >
        <img
          class="d-block img-fluid  w-100 slider_10"
          style="max-height:950px"
          src="{{path}}{{image.projectPicture}}"
          alt=""
          routerLink="projeler/proje/{{image.projectId}}"
        />
        <div class="carousel-caption 	.d-block .d-sm-none ">
          <h1 class="text-white bg-dark font-weight-bolder d-inline-flex p-2 bd-highlight">{{image.projectName}}</h1>
         <p routerLink="projeler/proje/{{image.projectId}}">Proje detayları için tıklayın.</p>
        </div>
      </div>
    </div>
    <a
      class="carousel-control-prev"
      href="#carouselExampleControls"
      role="button"
      data-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a
      class="carousel-control-next"
      href="#carouselExampleControls"
      role="button"
      data-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
  <app-footer></app-footer>
