import { Component, OnInit } from '@angular/core';
import {FormGroup,FormControl,Validator,FormBuilder, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
loginForm:FormGroup;
  constructor(private formBuilder:FormBuilder, private authService:AuthService, private router:Router) {
    if(this.authService.loggedIn())
    {
      this.router.navigate([""]);
    }
   }

  ngOnInit(): void {
    this.createLoginForm();
    
  }

createLoginForm(){
  this.loginForm=this.formBuilder.group({
    email:["",Validators.required],
    password:["",Validators.required]
  });
}
login()
{
  if(this.loginForm.valid)
  {

    let loginuser= Object.assign({},this.loginForm.value);
    this.authService.login(loginuser);
  }
}










}
