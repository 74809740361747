import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.css'],
})
export class GeneralSettingsComponent implements OnInit {
  changePasswordFormGroup: FormGroup;
  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private route:Router
  ) {}

  ngOnInit(): void {
    this.createchangePasswordForm();
  }
  createchangePasswordForm() {
    this.changePasswordFormGroup = this.formBuilder.group({
      password: ['', Validators.required],
      repeatPassword: ['', Validators.required],
    });
  }
  changeUserPassword() {
    const passwordModel = Object.assign({}, this.changePasswordFormGroup.value);
    console.log(passwordModel);
    if (passwordModel.password == passwordModel.repeatPassword) {
      passwordModel.userId = this.authService.userId;
      this.authService.changePassword(passwordModel).subscribe(
        (response) => {},
        (errorResponse) => {
          this.toastr.info(
            'Şifre değişikliği ' + errorResponse.error.text,
            'Bilgi'
          ),
            this.toastr.info(
              'Yeni Şifreniz: '+passwordModel.password,
              'Bilgi'
            );
            this.authService.logOut();
            this.route.navigateByUrl('/login');
        }
      );
    }
  }
}
