
<table class="table table-striped mt-3">
    <thead>
      <tr>
       <th scope="col">#</th>
       <th scope="col">İçerik</th>
       <th scope="col">Proje Adı</th>
       <th scope="col">İşlem</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let x of projectDetailService.pdpageDto">
        <th scope="row">{{x.projectDetailPageID}}</th>
        <td style="max-width:500px; word-wrap: break-word;">{{x.content}}</td>
        <td>{{x.projectName}}</td>
        <td><button type="button" (click)="deleteProjectDetail(x.projectDetailPageID)"class="btn btn-danger btn-sm">Sil</button></td>
      </tr>
      
    </tbody>
  </table>
 
