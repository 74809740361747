<h1 class="text-danger">Silme işlemi Yapacaksınız!</h1>
<div mat-dialog-content>
  <p class="fs-3">
    Bu işlem sonucunda projeye bağlı bileşenler silinecektir.
  </p>
</div>
<div>
  <button class="btn btn-danger mr-2" (click)="onNoClick(true)">Biliyorum, sil</button>
  <button class="btn btn-warning" (click)="onNoClick()" cdkFocusInitial>Vazgeç</button>
</div>

   
