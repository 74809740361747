
<table class="table table-striped mt-3">
    <thead>
      <tr>
       <th scope="col">#</th>
       <th scope="col">Proje</th>
       <th scope="col">Görsel Adı</th>
       <th scope="col">Kategori</th>
       <th scope="col">Mimari</th>
       <th scope="col">Görsel</th>
       <th scope="col">İşlem</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let x of galleries">
        <th scope="row">{{x.galleryId}}</th>
        <td>{{x.projectName}}</td>
        <td>{{x.pictureName}}</td>
        <td>{{x.categoryName}}</td>
        <td>{{x.categoryBaseName}}</td>
        <td><img src="{{path}}{{x.picture}}" class="img-thumbnail" style="max-width: 150px; max-height: 150px;"/></td>
        <td><button type="button" (click)="deleteGallery(x.galleryId)"class="btn btn-danger btn-sm">Sil</button></td>
      </tr>
      
    </tbody>
  </table>
 