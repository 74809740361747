import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
})
export class AdminComponent implements OnInit {
  constructor(private router:Router,private authservice: AuthService, private route: Router) {

  }
  ngOnInit(): void {

   this.adminControl();
  }
  adminControl():boolean{
    if(this.authservice.claimGuard("Admin"))
    {
      return true;
    }
    else{
      this.router.navigate(["/"]);
      return false;
    }
  }
}
