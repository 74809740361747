import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ProjectDetailPage } from 'src/app/models/projectDetailPage';
import { ProjectdetailService } from 'src/app/services/projectdetail.service';

@Component({
  selector: 'app-admin-list-and-delete-summary-pojectdetails',
  templateUrl: './admin-list-and-delete-summary-pojectdetails.component.html',
  styleUrls: ['./admin-list-and-delete-summary-pojectdetails.component.css'],
})
export class AdminListAndDeleteSummaryPojectdetailsComponent implements OnInit {
  projectDetails: ProjectDetailPage[];
  constructor(
    public projectDetailService: ProjectdetailService,
    private toastr: ToastrService,
    private router:Router
  ) {}

  ngOnInit(): void {
    this.getAllProjectDetailsDto();
  }

  getAllProjectDetailsDto() {
    this.projectDetailService.getAllProjectDetailsDto();
  }

  deleteProjectDetail(id: number) {
    this.toastr.info('Siliniyor', 'Bilgi');
    this.projectDetailService.deleteProjectDetailPage(id).subscribe(
      (response) => {},
      (errorResponse) => {
        if (errorResponse.status == 200) {
          this.toastr.success('Silme İşlemi Başarılı', 'Bilgi');
          this.getAllProjectDetailsDto();
        }
        if(errorResponse.status==401){
          this.toastr.error('Yetkiniz Yok', 'Bilgi');
        }
        if(errorResponse.status == 500){
          localStorage.clear();
          this.router.navigateByUrl('/login');
        }
        else{
          this.toastr.error(errorResponse.message, errorResponse.name);
        }

      }
    );
  }
}
