import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ProjectService } from 'src/app/services/project.service';

@Component({
  selector: 'app-admin-list-and-delete',
  templateUrl: './admin-list-and-delete.component.html',
  styleUrls: ['./admin-list-and-delete.component.css'],
})
export class AdminListAndDeleteComponent implements OnInit {
  
  listName="";
  constructor(private projectService: ProjectService,private authservice:AuthService,private router:Router) {
  
  }

  ngOnInit(): void {
   
  }


}
