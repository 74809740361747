<div class="container">
  <div class="row mt-2 mb-4">
    <div class="col-sm-6 mb-2">
      <div class="card">
        <div class="card-body">
          <h1 class="card-title">PROJE</h1>
          <p class="card-text">Bu bölümden proje oluşturabilirsiniz.</p>
          <form [formGroup]="projectAddForm" class="was-validated">
            <div class="form-group">
              <label for="projectName">Proje Adı*</label>
              <input
                type="text"
                class="form-control"
                id="projectName"
                formControlName="projectName"
                maxlength="50"
                aria-describedby="projectHelp"
                required
              />
              <small id="projectHelp" class="form-text text-muted"
                >50 Karakteri geçmeyecek şekilde proje adı yazın.</small
              >
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  formControlName="isVitrin"
                  id="isVitrin"
                />
                <label
                  class="form-check-label text-primary"
                  for="flexCheckDefault"
                >
                  Vitrine Ekle! (Seçilen Proje Ana Sayfada Gözükür)
                </label>
              </div>
            </div>
            <div class="form-group">
              <label for="projectDescription">Açıklama</label>
              <app-readonlycke
              [(ngModel)]="htmldata"
              rows="5"
              formControlName="projectDescription"
            ></app-readonlycke>
              <!-- <textarea
                class="form-control"
                id="mytextarea"
                rows="3"
                maxlength="300"
                aria-describedby="projectDescriptionHelp"
                formControlName="projectDescription"
              ></textarea> -->

              <small id="projectDescriptionHelp" class="form-text text-muted"
                >300 Karakteri geçmeyecek şekilde proje açıklaması yazın.</small
              >
            </div>

            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="projectPicture"
                    >Görsel*</span
                  >
                </div>
                <div class="custom-file file-box-design">
                  <input
                    type="file"
                    class="custom-file-input"
                    id="projectPicture"
                    accept=".jpg,.png,.jpeg,.jfif"
                    (change)="selectFiles($event)"
                    formControlName="projectPicture"

                    required
                  />

                  <input
                    class="custom-file-label img-btn-content"
                    for="projectPicture"
                    value="{{ imgBtnText }}"
                  />
                </div>
              </div>
              <div class="form-outline col-md-3">
                <label class="form-label" for="order">Proje Sırası*</label>
                <input
                  type="number"
                  id="order"
                  formControlName="order"
                  class="form-control"
                  min="1"
                />
              </div>

            </div>

          </form>
          <button (click)="uploadFiles()" class="btn btn-success">Ekle</button>
        </div>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="card">
        <div class="card-body">
          <h1 class="card-title">SANAL TUR</h1>
          <p class="card-text">Bu bölümde sanal tur oluşturabilirsiniz.</p>
          <form [formGroup]="virtualAddForm" class="was-validated">
            <div class="form-group">
              <label for="VirtualTourName">Sanal Tur Adı*</label>
              <input
                type="text"
                class="form-control"
                id="VirtualTourName"
                formControlName="name"
                maxlength="50"
                aria-describedby="virtualTourHelp"
                required
              />
              <small id="virtualTourHelp" class="form-text text-muted"
                >50 Karakteri geçmeyecek şekilde sanal tur adı yazın.</small
              >
            </div>
            <div class="form-group">
              <label for="VirtualTourDescription">Açıklama</label>
              <textarea
                class="form-control"
                id="VirtualTourDescription"
                formControlName="description"
                maxlength="300"
                rows="3"
              ></textarea>
              <small
                id="VirtualTourDescriptionHelp"
                class="form-text text-muted"
                >300 Karakteri geçmeyecek şekilde sanal tur açıklaması
                yazın.</small
              >
            </div>
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="CoverPhoto">Görsel*</span>
                </div>
                <div class="custom-file file-box-design">
                  <input
                    type="file"
                    class="custom-file-input"
                    id="CoverPhoto"
                    formControlName="CoverPhoto"
                    (change)="selectVirtoulTourCoverPhoto($event)"
                    accept=".jpg,.png,.jpeg"
                    aria-describedby="inputGroupFileAddon01"
                    required
                  />
                  <input
                    class="custom-file-label img-btn-content"
                    for="CoverPhoto"
                    value="{{ imgBtnTextVirtual }}"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <mat-form-field appearance="fill">
                <mat-label>Proje Seçiniz*</mat-label>
                <mat-select id="ProjectId" formControlName="projectId">
                  <mat-option
                    *ngFor="let project of projectService.projects"
                    [value]="project.projectId"
                    >{{ project.projectName }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <small id="categoryHelp" class="form-text text-muted"
                >Lütfen bağlı olacağı projeyi seçiniz.</small
              >
            </div>
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroupFileAddon01"
                    >Sanal Tur Dosyası*</span
                  >
                </div>
                <div class="custom-file file-box-design">
                  <input
                    type="file"
                    class="custom-file-input is-invalid"
                    name="files[]"
                    id="VirtualTourFolders"
                    formControlName="VirtualTourFolders"
                    (change)="selectFilesVirtualTours($event)"
                    multiple
                    accept="*"
                    directory=""
                    webkitdirectory=""
                    moxdirectory=""
                    aria-describedby="inputGroupFileAddon01"
                    required
                  />

                  <input
                    class="custom-file-label img-btn-content"
                    for="VirtualTourFolders"
                    value="{{ imgBtnTextVirtuals }}"
                  />
                </div>
              </div>
            </div>

            <div class="progress form-group" *ngIf="progress > 0">
              <div
                class="progress-bar progress-bar-striped bg-success"
                role="progressbar"
                [style.width.%]="progress"
              >
                {{ progress }}%
              </div>
            </div>
            <button (click)="uploadVirtualTourFiles()" class="btn btn-success">
              Ekle
            </button>
          </form>
        </div>
      </div>
    </div>
    <!-- <div class="col-sm-6">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">KATEGORİ</h5>
          <p class="card-text">Bu bölümde kategori oluşturabilirsiniz.</p>
          <form [formGroup]="categoryAddForm" class="was-validated">
            <div class="form-group">
              <label for="categoryName">Kategori Adı*</label>
              <input
                type="text"
                class="form-control"
                id="Name"
                formControlName="Name"
                maxlength="50"
                aria-describedby="categoryHelp"
                required
              />
              <small id="categoryHelp" class="form-text text-muted"
                >50 Karakteri geçmeyecek şekilde kategori ismi yazın.</small
              >
              <div class="form-check">
                <input class="form-check-input" type="checkbox" [(ngModel)]="isMainCategory"
                formControlName="Pid"
                value="0"
                id="Pid">
                <label class="form-check-label" for="Pid">
                  Ana Kategori Olarak Belirle
                </label>
              </div>
            </div>
            <button (click)="addCategory()" class="btn btn-success">Ekle</button>
          </form>
        </div>
      </div>
    </div> -->
  </div>

  <div class="row mt-2 mb-2">
    <div class="col-sm-6 col-md-12 col-lg-12 mb-2">
      <div class="card">
        <form enctype="multipart/form-data"></form>
        <div class="card-body">
          <h1 class="card-title">İÇERİK</h1>
          <p class="card-text">Bu bölümden içerik oluşturabilirsiniz.</p>
          <form [formGroup]="projectDetailAddForm" class="was-validated">
            <!-- <div class="form-check mb-4">
              <input
                class="form-check-input"
                type="checkbox"
                formControlName="isOnlyAdmin"
                id="adminCheck"
              />
              <label class="form-check-label" for="adminCheck">
                Sadece Yönetici Görebilsin!
              </label>
            </div> -->
            <div class="form-group">
              <mat-form-field appearance="fill">
                <mat-label>Proje Seçiniz*</mat-label>
                <mat-select formControlName="projectId" id="projectId">
                  <mat-option
                    *ngFor="let project of projectService.projects"
                    [value]="project.projectId"
                    >{{ project.projectName }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <small id="categoryHelp" class="form-text text-muted"
                >Lütfen bağlı olacağı projeyi seçiniz.</small
              >
            </div>
            <!-- <div class="form-group">
              <mat-form-field appearance="fill">
                <mat-label>Kategori Seçiniz*</mat-label>
                <mat-select
                  formControlName="categoryId"
                  id="categoryId"
                  required
                >
                  <mat-option
                    *ngFor="let category of categoryService.categoriesFilterAll"
                    [value]="category.categoryId"
                    >{{ category.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <small id="categoryHelp" class="form-text text-muted"
                >Lütfen bağlı olacağı kategori seçiniz.</small
              >
            </div> -->

            <!-- <div class="form-group">
              <mat-form-field appearance="fill">
                <mat-label>Mimari Seçiniz*</mat-label>
                <mat-select
                  formControlName="categoryBase"
                  id="categoryBase"
                  required
                >
                  <mat-option
                    *ngFor="
                      let category of categoryService.categoriesBaseFilterAll
                    "
                    [value]="category.categoryId"
                    >{{ category.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <small id="categoryHelp" class="form-text text-muted"
                >Lütfen bağlı olacağı mimariyi seçiniz.</small
              >
            </div> -->

            <!-- <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroupFileAddon01"
                    >Resimler*</span
                  >
                </div>
                <div class="custom-file">
                  <input
                    type="file"
                    class="custom-file-input"
                    id="galleryPicture"
                    multiple
                    accept="image/*"
                    (change)="selectFiles($event)"
                    formControlName="galleryPicture"
                    id="galleryPicture"
                    required
                  />
                  <label class="custom-file-label" for="galleryPicture"
                    >Resimleri Yükleyin</label
                  >
                </div>
              </div>
            </div> -->
            <label>Lütfen içerik kısmını boş bırakmayınız*</label>
            <app-readonlycke
              [(ngModel)]="htmldta"
              formControlName="content"
            ></app-readonlycke>
            <button (click)="addProjectDetailPage()" class="btn btn-success">
              Ekle
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
